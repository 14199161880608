import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import ContactUs from "./Pages/ContactUs";
import LatestBlogs from "./Pages/LatestBlogs";
import AboutUs from "./Pages/AboutUs";
import "./index.css";
import "./App.scss";
import Package from "./Pages/Package";
import PackageInner from "./Pages/PackageInner";
import LatestBlogsInner from "./Pages/LatestBlogsInner";
import HeaderComponent from "./Components/HeaderComponent/HeaderComponent";
import BannerComponent from "./Components/BannerComponent/BannerComponent";
import FooterComponent from "./Components/FooterComponent/FooterComponent";
import PkgBannerComponent from "./Components/PkgBannerComponent/PkgBannerComponent";
import axios from "axios";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicies from "./Pages/PrivacyPolicies";

function App() {
  const [path, setPath] = useState("");

  const [links, setLinks] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const social = await axios.get(
          "https://new.ecstaticexplorers.com/api/social-link"
        );
        setLinks(social.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    setPath(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      <Router>
        {" "}
        {/* Router wrapping all components */}
        <div>
          <HeaderComponent />
          {path.includes("/package/inner") ? (
            <PkgBannerComponent />
          ) : (
            <BannerComponent />
          )}
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/latest-blogs" element={<LatestBlogs socialLinks={links}/>} />
            <Route path="/latest-blogs-inner" element={<LatestBlogsInner />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/package" element={<Package />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policies" element={<PrivacyPolicies/>} />
            <Route path="/package/inner" element={<PackageInner />} />    
            <Route
              path="*"
              element={
                <div className="travel-container">
                  <div className="alignment">
                    <h1>404 Not Found</h1>
                  </div>
                </div>
              }
            />
          </Routes>
          <div className="side-social-bx">
            {links.map((item, index) => {
              return (
                <a
                  href={item.link}
                  target="_blank"
                  className={
                    item.name == "Instagram"
                      ? "insta-button s-btn"
                      : item.name == "Facebook"
                      ? "facebook-button s-btn"
                      : "youtube-button s-btn"
                  }
                >
                  <button>{item.name}</button>
                </a>
              );
            })}{" "}
          </div>
        </div>
        <FooterComponent socialLinks={links} />
      </Router>
      <div className="side-social-bx">
        {links.map((item, index) => {
          return (
            <a
              href={item.link}
              target="_blank"
              className={
                item.name == "Instagram"
                  ? "insta-button s-btn"
                  : item.name == "Facebook"
                  ? "facebook-button s-btn"
                  : "youtube-button s-btn"
              }
            >
              <button>{item.name}</button>
            </a>
          );
        })}{" "}
      </div>
    </>
  );
}

export default App;
