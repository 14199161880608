import React, { useEffect, useState } from "react";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import LatestBlogsCard from "../Components/LatestBlogsCard/LatestBlogsCard";
import { FaFacebookSquare, FaYoutubeSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import axios from "axios";
import ProgressItemComponent from "../Components/ProgressItemComponent/ProgressItemComponent";

const LatestBlogs = (props) => {
  const [blogData, setBlogData] = useState([]);
  const [categaryData, SetCategaryData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const allBlogs = await axios.get(
          "https://new.ecstaticexplorers.com/api/blogs"
        );
        const categary = await axios.get(
          "https://new.ecstaticexplorers.com/api/blogs"
        );
        setBlogData(allBlogs.data.data);
        SetCategaryData(categary.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="travel-container">
        <div className="alignment">
          <div class="blogs-section">
          <ProgressItemComponent /> 
            <h1>Latest Blogs</h1>
            <div class="row-latest-blogs">
              <div class="">
                {" "}
                {blogData.map((item, index) => {
                  return <LatestBlogsCard item={item} />;
                })}{" "}
              </div>

              <div class="">
                <div className="follow-us">
                  <h1>Follow Us</h1>

                  {props.socialLinks.map((obj, index) => {
                    return obj.name == "Facebook" ? (
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href={obj.link}
                        target="_blank"
                      >
                        {" "}
                        <div className="social">
                          <div className="icon_text">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5 0H2.5C1.12125 0 0 1.12125 0 2.5V17.5C0 18.8787 1.12125 20 2.5 20H17.5C18.8787 20 20 18.8787 20 17.5V2.5C20 1.12125 18.8787 0 17.5 0Z"
                                fill="#1976D2"
                              />
                              <path
                                d="M16.875 10H13.75V7.5C13.75 6.81 14.31 6.875 15 6.875H16.25V3.75H13.75C11.6788 3.75 10 5.42875 10 7.5V10H7.5V13.125H10V20H13.75V13.125H15.625L16.875 10Z"
                                fill="#FAFAFA"
                              />
                            </svg>

                            {/* <FaFacebookSquare className="facebook" size={31} /> */}
                            <p>Facebook</p>
                          </div>
                          <div className="btn">
                            <button>Like</button>
                          </div>
                        </div>{" "}
                      </a>
                    ) : obj.name == "Instagram" ? (
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href={obj.link}
                        target="_blank"
                      >
                        <div className="social">
                          <div className="icon_text">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_20_167)">
                                <path
                                  d="M1.24976 1.36083C-0.321907 2.99333 -0.000240855 4.7275 -0.000240855 9.99583C-0.000240855 14.3708 -0.763574 18.7567 3.23143 19.7892C4.47893 20.11 15.5323 20.11 16.7781 19.7875C18.4414 19.3583 19.7948 18.0092 19.9798 15.6567C20.0056 15.3283 20.0056 4.66917 19.9789 4.33417C19.7823 1.82833 18.2398 0.384165 16.2073 0.0916646C15.7414 0.0241646 15.6481 0.00416464 13.2581 -2.02656e-06C4.78059 0.00416464 2.92226 -0.373335 1.24976 1.36083Z"
                                  fill="url(#paint0_linear_20_167)"
                                />
                                <path
                                  d="M9.99785 2.61583C6.97202 2.61583 4.09869 2.34666 3.00119 5.16333C2.54785 6.32666 2.61369 7.8375 2.61369 10.0008C2.61369 11.8992 2.55285 13.6833 3.00119 14.8375C4.09619 17.6558 6.99285 17.3858 9.99619 17.3858C12.8937 17.3858 15.8812 17.6875 16.992 14.8375C17.4462 13.6625 17.3795 12.1742 17.3795 10.0008C17.3795 7.11583 17.5387 5.25333 16.1395 3.855C14.7229 2.43833 12.807 2.61583 9.99452 2.61583H9.99785ZM9.33619 3.94666C15.6479 3.93666 16.4512 3.235 16.0079 12.9825C15.8504 16.43 13.2254 16.0517 9.99869 16.0517C4.11535 16.0517 3.94619 15.8833 3.94619 9.9975C3.94619 4.04333 4.41285 3.95 9.33619 3.945V3.94666ZM13.9395 5.1725C13.7046 5.1725 13.4793 5.26583 13.3131 5.43195C13.147 5.59808 13.0537 5.82339 13.0537 6.05833C13.0537 6.29327 13.147 6.51858 13.3131 6.68471C13.4793 6.85084 13.7046 6.94416 13.9395 6.94416C14.1745 6.94416 14.3998 6.85084 14.5659 6.68471C14.732 6.51858 14.8254 6.29327 14.8254 6.05833C14.8254 5.82339 14.732 5.59808 14.5659 5.43195C14.3998 5.26583 14.1745 5.1725 13.9395 5.1725ZM9.99785 6.20833C9.49987 6.20839 9.00677 6.30652 8.54672 6.49715C8.08666 6.68777 7.66866 6.96713 7.31657 7.3193C6.60549 8.03053 6.20608 8.99511 6.20619 10.0008C6.2063 11.0066 6.60592 11.971 7.31716 12.6821C8.02839 13.3932 8.99296 13.7926 9.99869 13.7925C11.0044 13.7924 11.9689 13.3928 12.68 12.6815C13.391 11.9703 13.7905 11.0057 13.7904 10C13.7902 8.99428 13.3906 8.02979 12.6794 7.31871C11.9682 6.60764 11.0036 6.20822 9.99785 6.20833ZM9.99785 7.53916C13.252 7.53916 13.2562 12.4625 9.99785 12.4625C6.74452 12.4625 6.73952 7.53916 9.99785 7.53916Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_20_167"
                                  x1="1.28811"
                                  y1="18.7226"
                                  x2="19.876"
                                  y2="2.63498"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FFDD55" />
                                  <stop offset="0.5" stop-color="#FF543E" />
                                  <stop offset="1" stop-color="#C837AB" />
                                </linearGradient>
                                <clipPath id="clip0_20_167">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>

                            {/* <FaSquareInstagram className="instagram" size={32} /> */}
                            <p>Instagram</p>
                          </div>
                          <div className="btn">
                            <button>Follow</button>
                          </div>
                        </div>
                      </a>
                    ) : obj.name == "Youtube" ? (
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href={obj.link}
                        target="_blank"
                      >
                        <div className="social">
                          <div className="icon_text">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_20_171)">
                                <path
                                  d="M16.4228 19.6591C12.1547 20.1145 7.85013 20.1145 3.58194 19.6591C2.7531 19.5716 1.9793 19.2026 1.38955 18.6137C0.799793 18.0247 0.429724 17.2514 0.341033 16.4227C-0.114329 12.1545 -0.114329 7.85001 0.341033 3.58182C0.42856 2.75298 0.797544 1.97918 1.38647 1.38942C1.9754 0.799671 2.74868 0.429601 3.5774 0.340911C7.84559 -0.114451 12.1501 -0.114451 16.4183 0.340911C17.2471 0.428438 18.0209 0.797422 18.6107 1.38635C19.2005 1.97527 19.5705 2.74855 19.6592 3.57727C20.1146 7.84547 20.1146 12.15 19.6592 16.4182C19.5717 17.247 19.2027 18.0208 18.6138 18.6106C18.0249 19.2003 17.2516 19.5704 16.4228 19.6591Z"
                                  fill="#E60000"
                                />
                                <path
                                  d="M15.9582 6.99318C15.7082 6.22386 14.881 5.75796 14.1196 5.65114C11.3811 5.36022 8.61947 5.36022 5.88096 5.65114C5.1196 5.75796 4.29005 6.21932 4.04232 6.99318C3.65256 8.97916 3.65256 11.022 4.04232 13.008C4.29232 13.7761 5.1196 14.2432 5.88096 14.35C8.61947 14.6409 11.3811 14.6409 14.1196 14.35C14.881 14.2432 15.7105 13.7818 15.9582 13.008C16.348 11.022 16.348 8.97916 15.9582 6.99318ZM8.50482 12.3955V7.60455L12.4048 10C11.0901 10.808 9.81392 11.5909 8.50482 12.3955Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_20_171">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>

                            {/* <FaYoutubeSquare className="youtube" size={32} /> */}
                            <p>YouTube</p>
                          </div>
                          <div className="btn">
                            <button>Subscribe</button>
                          </div>
                        </div>
                      </a>
                    ) : (
                      ""
                    );
                  })}
                </div>

                <div class="categories follow-us">
                  <h1>Categories</h1>
                  {categaryData.map((item, index) => {
                    return (
                      <div class="cata-box">
                        <div class="img-parent">
                          <img src={item.file_path} alt=""></img>
                          <div class="text-parent">
                            <h3>{item.title}</h3>
                            {/* <h4>{item.description}</h4> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div class="text-center-btn">
              <button class="view-more">View More</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestBlogs;
