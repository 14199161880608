import React from "react";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import ReachUsComponent from "../Components/ReachUsComponent/ReachUsComponent";
import CommunicationComponent from "../Components/CommunicationComponent/CommunicationComponent";
import MapComponent from "../Components/MapComponent/MapComponent";
import ProgressItemComponent from "../Components/ProgressItemComponent/ProgressItemComponent";

const ContactUs = () => {
  return (
    <>
      <div className="travel-container">
        <div className="alignment">
          <div className="travel-container">
            <div className="alignment">
              <section className="contact-us-section">
                <ProgressItemComponent />

                <div className="contact-us">
                  <div className="contact-box">
                    <CommunicationComponent />
                  </div>
                  <div className="inq-box">
                    <ReachUsComponent />
                  </div>
                  {/* <div className="map-box">
                <MapComponent />
              </div> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="map-box">
        <MapComponent />
      </div>
    </>
  );
};

export default ContactUs;
