import React, { useEffect, useState } from "react";
import "./doDontComponent.scss";
import axios from "axios";

const DoComponent = () => {
  const [dosList, setDosList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://new.ecstaticexplorers.com/api/do");
        setDosList(res.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };
    fetchData();
  }, []);

  return (
    <div className="do-card">
      <div className="do-card-header">
        <span className="icon">
          <svg
            width="40"
            height="40"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6783 18.4378H4.24452C3.65685 18.4393 3.09376 18.6738 2.67878 19.0899C2.26381 19.506 2.03084 20.0697 2.03101 20.6574V41.5642C2.03084 42.1518 2.26381 42.7156 2.67878 43.1317C3.09376 43.5478 3.65685 43.7823 4.24452 43.7838H10.6783C11.2666 43.7825 11.8304 43.5482 12.2464 43.1322C12.6624 42.7163 12.8966 42.1524 12.8979 41.5642V20.6574C12.8966 20.0691 12.6624 19.5053 12.2464 19.0893C11.8304 18.6734 11.2666 18.4391 10.6783 18.4378ZM42.0141 25.7169C42.014 24.7136 41.6159 23.7514 40.9074 23.0412C40.847 22.9757 40.782 22.9147 40.7128 22.8588C41.4975 22.5262 42.1433 21.9328 42.5408 21.1789C42.9384 20.4251 43.0634 19.557 42.8946 18.7216C42.7258 17.8862 42.2736 17.1347 41.6145 16.5943C40.9554 16.054 40.1299 15.7578 39.2776 15.7561H26.0817C26.1547 15.6284 26.2215 15.5128 26.2824 15.3973C26.6621 14.7843 26.9644 14.1265 27.1824 13.4392C27.4733 12.4863 27.6508 11.5025 27.7114 10.5081C27.7956 9.07138 27.5915 7.63234 27.1111 6.27571C26.6307 4.91908 25.8837 3.67229 24.9141 2.60876C24.5488 2.19384 24.1038 1.8567 23.6054 1.61746C23.1071 1.37821 22.5657 1.24174 22.0134 1.2162C21.2591 1.18723 20.5217 1.44432 19.9488 1.93597C19.376 2.42762 19.01 3.11754 18.9243 3.86755C18.9192 3.89563 18.9172 3.92417 18.9182 3.95268V9.27971L15.3911 18.2189C15.1023 18.9459 14.6677 19.6061 14.1141 20.1588V40.7615C14.9847 41.0012 15.8705 41.1821 16.7655 41.3027C17.0981 41.3472 17.4333 41.3695 17.7688 41.3696H35.6533C36.5372 41.3684 37.385 41.0188 38.0128 40.3966C38.3217 40.0876 38.5667 39.7206 38.7337 39.3168C38.9006 38.913 38.9863 38.4802 38.9857 38.0432C38.9879 37.2368 38.6935 36.4576 38.1587 35.854C38.8631 35.706 39.5113 35.3619 40.0288 34.8616C40.5462 34.3612 40.9117 33.7248 41.0833 33.0258C41.2549 32.3268 41.2255 31.5935 40.9986 30.9104C40.7717 30.2273 40.3564 29.6222 39.8006 29.1648C40.4606 28.8619 41.0199 28.3761 41.4123 27.765C41.8046 27.1539 42.0135 26.4431 42.0141 25.7169Z"
              fill="white"
            />
          </svg>
        </span>
        <h2>Do's</h2>
      </div>
      <ul className="do-list">
        {dosList.map((item, index) => (
          <li key={index}>
            <span className="checkmark">
              <svg
                width="23"
                height="21"
                viewBox="0 0 23 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.02511 20.3567C13.7121 20.3567 17.5117 16.5572 17.5117 11.8702C17.5117 7.18322 13.7121 3.38367 9.02511 3.38367C4.33813 3.38367 0.538574 7.18322 0.538574 11.8702C0.538574 16.5572 4.33813 20.3567 9.02511 20.3567Z"
                  fill="#B0EF8F"
                />
                <path
                  d="M9.12119 18.4297L9.01354 18.2462C7.36931 15.4448 2.99999 9.49971 2.95585 9.43999L2.89282 9.35437L4.38143 7.88321L9.09354 11.1736C12.0604 7.3236 14.8283 4.67927 16.6338 3.13509C18.6088 1.44591 19.8945 0.668263 19.9074 0.660811L19.9367 0.643311H22.4618L22.2206 0.858118C16.0172 6.38341 9.2935 18.1269 9.22652 18.2449L9.12119 18.4297Z"
                  fill="#009045"
                />
              </svg>
            </span>{" "}
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DoComponent;
