import React, { useEffect, useState } from "react";
import CardComponent from "../CardComponent/CardComponent";
import axios from "axios";
import "./relatedPkgComponent.scss";

const RelatedPkgComponent = () => {
  const [states, setStates] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://new.ecstaticexplorers.com/api/get-explore-by-states"
        );
        setStates(res.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="related-pkg">
        <h2>Related Packages</h2>

        <div className="card-related">
          <CardComponent
            allStates={states}
            isDescNeeded={true}
            isExploreUnexpected={false}
            parentCont="package"
            isDataFrmApi={true}
            isMostPopularPakage={false}
            isRelatedPkg={true}
          />{" "}<span style={{marginTop: '1rem'}}>

          </span>
          <CardComponent
            allStates={states}
            isDescNeeded={true}
            isExploreUnexpected={false}
            parentCont="package"
            isDataFrmApi={true}
            isMostPopularPakage={false}
            isRelatedPkg={true}
          />
        </div>
      </div>
    </>
  );
};

export default RelatedPkgComponent;
