import React, { useEffect } from "react";
import "./latestBlogsCard.scss";
import { Link } from "react-router-dom";
import axios from "axios";

const LatestBlogsCard = ({ item }) => {
  return (
    <Link
      to={"/latest-blogs-Inner?" + item.id }
      style={{
        color: "black",
        textDecoration: "none",
      }}
    >
      {" "}
      <div class="categories-item">
        <div class="img-parent">
          <img src={item.file_path} alt=""></img>
        </div>
        <div class="text-section"><span>
          {item.blog_tags.map((obj, key) => {
            return(<p class="path"> {obj.tag.name+"/"}</p>);
          })}</span>

          <h2>{item.title}</h2>
          <p class="details" dangerouslySetInnerHTML={{ __html: item.text }} />

          {/* <p class="details" >
            Our Journey To Kashmir Began On April 4, 2024, With A Flight From
            Kolkata To Delhi. We Then Travelled By Train To Jammu...
          </p> */}
        </div>
      </div>
    </Link>
  );
};

export default LatestBlogsCard;
