import React, { useState } from "react";
import "./pkgFilterComponent.scss";

const PkgFilterComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [minBudget, setMinBudget] = useState(5000);
  const [maxBudget, setMaxBudget] = useState(20000);
  const [selectedRange, setSelectedRange] = useState({
    below25k: false,
    between25k35k: false,
    between35k40k: false,
    above40k: false,
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMinSliderChange = (e) => {
    setMinBudget(parseInt(e.target.value));
    clearCheckboxSelection();
    sendBudgetInfoBack();
  };

  const handleMaxSliderChange = (e) => {
    setMaxBudget(parseInt(e.target.value));
    clearCheckboxSelection();
    sendBudgetInfoBack();
  };

  const clearCheckboxSelection = () => {
    setSelectedRange({
      below25k: false,
      between25k35k: false,
      between35k40k: false,
      above40k: false,
    });
    sendBudgetInfoBack();
  };

  const handleCheckboxChange = (e, min, max, range) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setMinBudget(min);
      setMaxBudget(max);
      setSelectedRange({
        ...selectedRange,
        [range]: true,
      });
    } else {
      setSelectedRange({
        ...selectedRange,
        [range]: false,
      });
    }
    sendBudgetInfoBack();
  };

  const sendBudgetInfoBack = () => {
    props.handleClick(selectedRange, minBudget, maxBudget);
  };

  return (
    <div className="budget-filter">
      <div className="budget-heading" onClick={toggleDropdown}>
        <h2 className="budget-top" >Budget (Per Person)</h2>
        <span className="d-arrow">
          {isOpen ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.50003 10.3125C7.43847 10.3126 7.3775 10.3005 7.32062 10.2769C7.26375 10.2534 7.21209 10.2188 7.16862 10.1752L2.48112 5.48769C2.29796 5.30452 2.29796 5.00792 2.48112 4.82487C2.66429 4.64183 2.96089 4.64171 3.14394 4.82487L7.50003 9.18097L11.8561 4.82487C12.0393 4.64171 12.3359 4.64171 12.5189 4.82487C12.702 5.00804 12.7021 5.30464 12.5189 5.48769L7.83144 10.1752C7.78796 10.2188 7.73631 10.2534 7.67943 10.2769C7.62256 10.3005 7.56159 10.3126 7.50003 10.3125Z"
                fill="#63AB45"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.50003 4.6875C7.56159 4.6874 7.62256 4.69948 7.67943 4.72301C7.73631 4.74654 7.78796 4.78117 7.83144 4.82475L12.5189 9.51225C12.7021 9.69542 12.7021 9.99202 12.5189 10.1751C12.3358 10.3583 12.0392 10.3583 11.8561 10.1751L7.50003 5.81903L3.14394 10.1751C2.96089 10.3583 2.66429 10.3583 2.48112 10.1751C2.29796 9.99202 2.29796 9.69542 2.48112 9.51225L7.16862 4.82475C7.21209 4.78117 7.26375 4.74654 7.32062 4.72301C7.3775 4.69948 7.43847 4.6874 7.50003 4.6875Z"
                fill="#63AB45"
              />
            </svg>
          )}
        </span>
      </div>

      {isOpen && (
        <div className="budget-dropdown">
          <div className="slider-container">
            <div className="range-slider">
              <input
                type="range"
                min="5000"
                max="50000"
                value={minBudget}
                onChange={handleMinSliderChange}
                className="slider"
              />
              <input
                type="range"
                min="5000"
                max="50000"
                value={maxBudget}
                onChange={handleMaxSliderChange}
                className="slider"
              />
            </div>
            <div className="min-max-values">
              <span>₹{minBudget.toLocaleString()}</span>
              <span className="max-amount">₹{maxBudget.toLocaleString()}</span>
            </div>
          </div>

          <div className="budget-options">
            <label>
              <input
                type="checkbox"
                checked={selectedRange.below25k}
                onChange={(e) =>
                  handleCheckboxChange(e, 5000, 25000, "below25k")
                }
              />
              <span className="amount">
                <span> &lt; ₹25,000</span>
                <span className="count">(92)</span>
              </span>
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedRange.between25k35k}
                onChange={(e) =>
                  handleCheckboxChange(e, 25000, 35000, "between25k35k")
                }
              />
              <span className="amount">
                <span>₹25,000 - ₹35,000</span>
                <span className="count">(139)</span>
              </span>
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedRange.between35k40k}
                onChange={(e) =>
                  handleCheckboxChange(e, 35000, 40000, "between35k40k")
                }
              />
              <span className="amount">
                <span>₹35,000 - ₹40,000</span>
                <span className="count">(68)</span>
              </span>
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedRange.above40k}
                onChange={(e) =>
                  handleCheckboxChange(e, 40000, 40000, "above40k")
                }
              />
              <span className="amount">
                <span>&gt; ₹40,000</span>
                <span className="count">(71)</span>
              </span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default PkgFilterComponent;
