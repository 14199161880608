import React, { useEffect, useState } from "react";
import "./exploreUnexpectedComponent.scss";
import SellingPackageComponent from "../SellingPackageComponent/SellingPackageComponent";
import axios from "axios";
import { Link } from "react-router-dom";

const ExploreUnexpectedComponent = () => {

  const [states, setStates] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://new.ecstaticexplorers.com/api/get-explore-the-unexplored-states "
        );
        setStates(res.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <div className="unexpected">
      <SellingPackageComponent
        title="Explore the unexplored"
        exploreUnexpectedData={states}
      />
    </div> 
  );
};

export default ExploreUnexpectedComponent;
