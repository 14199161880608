import React, { useEffect, useState } from "react";
import axios from "axios";

const PrivacyPolicies = () => {


  const [aboutData, setAboutData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://new.ecstaticexplorers.com/api/privacy-policy");
        setAboutData(res.data.data);
        setAboutData(res.data.data.length)
        setAboutData(res.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);
    




  return (
    <div  className="travel-container">
      <div className="alignment">



<div  >
 <h1>{aboutData.name}</h1>
 <img src={aboutData.file_path} alt="img" />
<p dangerouslySetInnerHTML={{ __html: aboutData.description }}></p>
  
</div>



      </div>
    </div>
  );
};

export default PrivacyPolicies;