import React from "react";
import "./instaComponent.scss";

const InstaComponent = () => {
  const imageUrls = [
    "https://www.freakytravel.com/wp-content/uploads/hiker-ocean-extreme.jpg",
    "https://www.freakytravel.com/wp-content/uploads/hiker-ocean-extreme.jpg",
    "https://www.freakytravel.com/wp-content/uploads/hiker-ocean-extreme.jpg",
    "https://www.freakytravel.com/wp-content/uploads/hiker-ocean-extreme.jpg",
    "https://www.freakytravel.com/wp-content/uploads/hiker-ocean-extreme.jpg",
    "https://www.freakytravel.com/wp-content/uploads/hiker-ocean-extreme.jpg",
    "https://www.freakytravel.com/wp-content/uploads/hiker-ocean-extreme.jpg",
    "https://www.freakytravel.com/wp-content/uploads/hiker-ocean-extreme.jpg",
  ];

  return (
    <div className="insta">
      <h1>Live Instagram Feed</h1>
      <div className="insta-box">
        <div className="insta-c1">
          <div className="insta-c1-r1">
            {imageUrls.slice(0, 3).map((url, index) => (
              <div key={index} className={`insta-c1-r1-c${index + 1}`}>
                <img className="insta-img" src={url} alt={`Example ${index + 1}`} />
              </div>
            ))}
          </div>
          <div className="insta-c1-r2">
            {imageUrls.slice(3, 5).map((url, index) => (
              <div key={index} className={`insta-c1-r2-c${index + 1} ${index === 1 ? 'max-hi' : ''}`}>
                <img className="insta-img" src={url} alt={`Example ${index + 4}`} />
              </div>
            ))}
          </div>
        </div>
        <div className="insta-c2">
          <div className="insta-c2-c1">
            {imageUrls.slice(5, 7).map((url, index) => (
              <div key={index} className={`insta-c2-c1-r${index + 1}`}>
                <img className="insta-img" src={url} alt={`Example ${index + 6}`} />
              </div>
            ))}
          </div>
          <div className="insta-c2-c2">
            <img className="insta-img" src={imageUrls[7]} alt="Example 8" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstaComponent;
