import React from "react";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import LatestBlogsInnerComponent from "../Components/LatestBlogsInnerComponent/LatestBlogsInnerComponent";
import LatestBlogsContentsComponent from "../Components/LatestBlogsContentsComponent/LatestBlogsContentsComponent";
import ProgressItemComponent from "../Components/ProgressItemComponent/ProgressItemComponent";
// import { FaFacebookSquare, FaYoutubeSquare} from "react-icons/fa";
// import { FaSquareInstagram } from "react-icons/fa6";

const LatestBlogsInner = () => {
  return (
    <>
      <div className="travel-container">
        <div className="alignment">
          <div className="latest-blogs-inner">
            <ProgressItemComponent />
            <div className="inner-blog-section">
              <p className="zero">
                Betabvalley dal lake Gondola gondolaride gulmarg honeymoon trip
                kashmir Pahalgam sonmarg Travel by Car Tulips Uncategorized
              </p>
              <LatestBlogsInnerComponent />
              <div className="social-inner">
                <div className="social-inner-box">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8598 4.65274L11.5265 0.152795C11.4573 0.0811739 11.3684 0.0318131 11.271 0.0110268C11.1736 -0.00975947 11.0723 -0.00102628 10.9799 0.0361093C10.8874 0.0734423 10.8081 0.137553 10.7523 0.220222C10.6965 0.302891 10.6666 0.40035 10.6665 0.500104V2.66683H10.4999C6.91597 2.66683 4 5.58279 4 9.16675V10.1667C4 10.3987 4.16331 10.592 4.38928 10.6448C4.42603 10.654 4.46265 10.658 4.49928 10.658C4.68799 10.658 4.86865 10.5474 4.95593 10.3734C5.89329 8.49804 7.77789 7.3334 9.87456 7.3334H10.6665V9.5C10.6665 9.5997 10.6963 9.69712 10.7522 9.77971C10.808 9.8623 10.8873 9.92627 10.9799 9.96337C11.1673 10.04 11.3852 9.9934 11.5265 9.84668L15.8598 5.34673C16.0465 5.15277 16.0465 4.84746 15.8598 4.65277V4.65274Z"
                      fill="#1672D5"
                    />
                    <path
                      d="M13.9998 15.9999H1.99998C0.897333 15.9999 0 15.1027 0 13.9999V4.66679C0 3.56414 0.897333 2.66681 1.99998 2.66681H3.99995C4.3686 2.66681 4.66657 2.96477 4.66657 3.33343C4.66657 3.70208 4.3686 4.00004 3.99995 4.00004H1.99998C1.63195 4.00004 1.33323 4.29876 1.33323 4.66679V13.9999C1.33323 14.368 1.63195 14.6667 1.99998 14.6667H13.9998C14.3677 14.6667 14.6665 14.368 14.6665 13.9999V8.66674C14.6665 8.29809 14.9644 8 15.3331 8C15.7018 8 15.9998 8.29809 15.9998 8.66674V13.9999C15.9998 15.1027 15.1025 15.9999 13.9998 15.9999Z"
                      fill="#1672D5"
                    />
                  </svg>
                  <p>Share</p>

                  <a
                    href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL"
                    target="_blank"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.4999 0.625C-2.22309 1.05625 -3.72459 21.7157 10.6349 24.375H14.3649C28.7277 21.7132 27.2197 1.05475 12.4999 0.625Z"
                        fill="#1877F2"
                      />
                      <path
                        d="M14.3648 16.026H17.146L17.6753 12.5725H14.3648V10.3312C14.3648 9.3865 14.8273 8.4655 16.31 8.4655H17.8153V5.52525C14.5918 4.9445 10.719 5.12125 10.6348 9.94025V12.5725H7.604V16.026H10.6348V24.375H14.3648V16.026Z"
                        fill="#F1F1F1"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/direct/inbox/"
                    target="_blank"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5Z"
                        fill="url(#paint0_linear_30_785)"
                      />
                      <path
                        d="M21.9078 4.27628C20.0854 7.62168 17.5081 11.0723 14.2877 14.2927C11.0673 17.5131 7.61664 20.0855 4.27618 21.9079C4.06675 21.7258 3.86335 21.5362 3.66598 21.3388C2.47298 20.1856 1.5215 18.8065 0.867025 17.2818C0.212548 15.7571 -0.131829 14.1173 -0.146027 12.4582C-0.160224 10.799 0.156042 9.15359 0.784331 7.61791C1.41262 6.08224 2.34036 4.68701 3.51345 3.5136C4.68654 2.34018 6.0815 1.41205 7.617 0.783333C9.1525 0.154616 10.7978 -0.16211 12.457 -0.148375C14.1162 -0.134641 15.756 0.209279 17.2809 0.86333C18.8058 1.51738 20.1852 2.46847 21.3387 3.66115C21.536 3.85852 21.7257 4.06356 21.9078 4.27628Z"
                        fill="white"
                        fill-opacity="0.0627451"
                      />
                      <path
                        d="M15.4604 5.92102H9.53932C8.57965 5.92102 7.6593 6.30225 6.98071 6.98083C6.30212 7.65942 5.9209 8.57978 5.9209 9.53944V15.4605C5.9209 16.4202 6.30212 17.3405 6.98071 18.0191C7.6593 18.6977 8.57965 19.0789 9.53932 19.0789H15.4604C16.42 19.0789 17.3404 18.6977 18.019 18.0191C18.6976 17.3405 19.0788 16.4202 19.0788 15.4605V9.53944C19.0788 8.57978 18.6976 7.65942 18.019 6.98083C17.3404 6.30225 16.42 5.92102 15.4604 5.92102ZM17.9275 14.9408C17.9275 15.7329 17.6128 16.4926 17.0527 17.0528C16.4925 17.6129 15.7328 17.9276 14.9406 17.9276H10.0591C9.2669 17.9276 8.50718 17.6129 7.94704 17.0528C7.3869 16.4926 7.07221 15.7329 7.07221 14.9408V10.0592C7.07221 9.26702 7.3869 8.5073 7.94704 7.94716C8.50718 7.38702 9.2669 7.07234 10.0591 7.07234H14.9406C15.7328 7.07234 16.4925 7.38702 17.0527 7.94716C17.6128 8.5073 17.9275 9.26702 17.9275 10.0592V14.9408Z"
                        fill="white"
                      />
                      <path
                        d="M14.8997 10.125L14.8684 10.0938L14.8421 10.0675C14.2202 9.44773 13.378 9.09994 12.5 9.10036C12.0567 9.10337 11.6182 9.19371 11.2098 9.36621C10.8014 9.53871 10.4309 9.79 10.1197 10.1057C9.80838 10.4214 9.56235 10.7954 9.39564 11.2062C9.22892 11.617 9.14479 12.0567 9.14804 12.5C9.14737 13.3998 9.50204 14.2634 10.1349 14.903C10.4449 15.2167 10.8142 15.4656 11.2214 15.635C11.6287 15.8045 12.0655 15.8911 12.5066 15.8898C13.1682 15.876 13.8114 15.6699 14.3579 15.2967C14.9043 14.9235 15.3304 14.3994 15.5841 13.7883C15.8378 13.1771 15.9082 12.5053 15.7866 11.8549C15.6651 11.2044 15.3569 10.6033 14.8997 10.125ZM12.5 14.7303C12.0576 14.7365 11.6234 14.611 11.2525 14.3697C10.8817 14.1284 10.591 13.7823 10.4174 13.3754C10.2438 12.9684 10.1951 12.5191 10.2776 12.0844C10.3601 11.6497 10.57 11.2494 10.8806 10.9344C11.1913 10.6193 11.5886 10.4038 12.022 10.3152C12.4555 10.2266 12.9055 10.2689 13.3149 10.4367C13.7242 10.6046 14.0744 10.8904 14.3209 11.2578C14.5674 11.6252 14.699 12.0576 14.699 12.5C14.7012 12.7909 14.646 13.0793 14.5367 13.3489C14.4273 13.6184 14.2659 13.8637 14.0617 14.0708C13.8575 14.278 13.6144 14.4428 13.3465 14.556C13.0785 14.6691 12.7909 14.7284 12.5 14.7303ZM16.8471 8.9441C16.8479 9.04965 16.828 9.15432 16.7883 9.25214C16.7487 9.34996 16.6901 9.439 16.616 9.51416C16.542 9.58933 16.4538 9.64914 16.3565 9.69018C16.2593 9.73121 16.1549 9.75267 16.0494 9.75332C15.9447 9.75327 15.8411 9.73245 15.7445 9.69208C15.648 9.65171 15.5604 9.59258 15.4869 9.51812C15.3754 9.40507 15.2996 9.26179 15.2689 9.10607C15.2381 8.95034 15.2537 8.78902 15.3138 8.64209C15.3739 8.49516 15.4757 8.36909 15.6068 8.27951C15.7378 8.18994 15.8923 8.1408 16.051 8.13818C16.2368 8.13815 16.4166 8.20334 16.5592 8.32239L16.5757 8.33884C16.6022 8.36043 16.6264 8.38468 16.648 8.41121L16.6661 8.43095C16.7839 8.57599 16.8478 8.7573 16.8471 8.9441Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_30_785"
                          x1="3.66118"
                          y1="3.66118"
                          x2="21.3388"
                          y2="21.3388"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FAE100" />
                          <stop offset="0.15" stop-color="#FCB720" />
                          <stop offset="0.3" stop-color="#FF7950" />
                          <stop offset="0.5" stop-color="#FF1C74" />
                          <stop offset="1" stop-color="#6C1CD1" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </a>

                  <a
                    href="https://wa.me/?text=Check%20out%20this%20cool%20article!"
                    target="_blank"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_30_792)">
                        <path
                          d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
                          fill="#29A71A"
                        />
                        <path
                          d="M18.0111 6.98867C16.7106 5.67517 14.9835 4.86965 13.1414 4.71739C11.2993 4.56514 9.46335 5.07618 7.96483 6.15833C6.4663 7.24048 5.40381 8.82251 4.969 10.6191C4.53418 12.4156 4.75567 14.3084 5.59351 15.956L4.77106 19.9489C4.76253 19.9886 4.76229 20.0297 4.77035 20.0696C4.77842 20.1094 4.79462 20.1471 4.81794 20.1804C4.8521 20.231 4.90086 20.2699 4.95772 20.2919C5.01457 20.314 5.07681 20.3182 5.13612 20.304L9.04947 19.3765C10.6925 20.1931 12.5719 20.4003 14.3533 19.9613C16.1348 19.5223 17.7027 18.4655 18.7781 16.979C19.8535 15.4924 20.3666 13.6726 20.2262 11.8432C20.0857 10.0138 19.3008 8.29363 18.0111 6.98867ZM16.791 16.7273C15.8911 17.6246 14.7324 18.2169 13.4781 18.4208C12.2238 18.6247 10.9372 18.4298 9.79947 17.8637L9.25402 17.5938L6.85487 18.162L6.86197 18.1321L7.35913 15.7174L7.09209 15.1904C6.51078 14.0487 6.30572 12.7524 6.50627 11.487C6.70683 10.2217 7.30271 9.05228 8.20856 8.14634C9.34678 7.00847 10.8903 6.36926 12.4998 6.36926C14.1092 6.36926 15.6527 7.00847 16.791 8.14634C16.8006 8.15745 16.8111 8.1679 16.8222 8.17759C17.9463 9.31839 18.5738 10.8573 18.568 12.4589C18.5621 14.0604 17.9234 15.5948 16.791 16.7273Z"
                          fill="white"
                        />
                        <path
                          d="M16.5777 14.956C16.2837 15.4191 15.8192 15.9859 15.2354 16.1265C14.2127 16.3736 12.6431 16.135 10.6899 14.314L10.6658 14.2927C8.94845 12.7003 8.50243 11.3751 8.61039 10.3239C8.67004 9.72733 9.1672 9.18756 9.58624 8.83528C9.65249 8.77874 9.73105 8.73849 9.81563 8.71774C9.90022 8.69698 9.98849 8.69631 10.0734 8.71578C10.1583 8.73524 10.2374 8.7743 10.3045 8.82983C10.3716 8.88536 10.4248 8.95581 10.4598 9.03557L11.0919 10.456C11.133 10.5481 11.1482 10.6497 11.136 10.7498C11.1237 10.8499 11.0844 10.9447 11.0223 11.0242L10.7027 11.439C10.6341 11.5246 10.5928 11.6288 10.5839 11.7382C10.575 11.8475 10.5991 11.957 10.653 12.0526C10.832 12.3665 11.261 12.8282 11.7368 13.2557C12.2709 13.7387 12.8632 14.1805 13.2382 14.331C13.3386 14.372 13.4489 14.382 13.555 14.3597C13.661 14.3375 13.758 14.2839 13.8334 14.206L14.2041 13.8324C14.2757 13.7619 14.3646 13.7116 14.4619 13.6866C14.5593 13.6617 14.6615 13.663 14.7581 13.6904L16.2595 14.1165C16.3424 14.1419 16.4183 14.1859 16.4815 14.2452C16.5447 14.3044 16.5935 14.3774 16.6242 14.4584C16.6549 14.5394 16.6667 14.6263 16.6586 14.7126C16.6506 14.7988 16.6229 14.8821 16.5777 14.956Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_30_792">
                          <rect width="25" height="25" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://wa.me/?text=Check%20out%20this%20cool%20article!"
                    target="_blank"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
                        fill="#2196F3"
                      />
                      <path
                        d="M12.4971 13.1172L5.9082 9.83008V16.3613C5.90911 16.6924 6.04104 17.0097 6.27517 17.2439C6.50931 17.478 6.8266 17.6099 7.15771 17.6108H17.8423C18.1734 17.6099 18.4907 17.478 18.7248 17.2439C18.959 17.0097 19.0909 16.6924 19.0918 16.3613V9.89893L12.4971 13.1172Z"
                        fill="white"
                      />
                      <path
                        d="M12.5029 11.8828L19.0918 8.66699V8.63867C19.0909 8.30756 18.959 7.99026 18.7248 7.75613C18.4907 7.522 18.1734 7.39006 17.8423 7.38916H7.15771C6.83422 7.3898 6.5235 7.51554 6.29061 7.74007C6.05771 7.96459 5.92068 8.27049 5.9082 8.59375L12.5029 11.8828Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a href="https://imgur.com/yourimage" target="_blank">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_30_808)">
                        <path
                          d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
                          fill="#FFCE00"
                        />
                        <path
                          d="M25 12.5C25 11.6053 24.906 10.7325 24.7273 9.89102L18.37 3.53369L3.75977 16.9252L8.02769 21.1932L7.67749 21.4776L11.1251 24.9252C11.5817 24.9752 12.0407 25.0001 12.5 25C19.4036 25 25 19.4036 25 12.5Z"
                          fill="#FFA300"
                        />
                        <path
                          d="M19.9747 17.5006H5.02529C4.09717 17.5006 3.34473 16.7482 3.34473 15.8201V9.73818C3.34473 8.81006 4.09712 8.05762 5.02529 8.05762H19.9747C20.9028 8.05762 21.6553 8.81001 21.6553 9.73818V15.8201C21.6553 16.7482 20.9029 17.5006 19.9747 17.5006Z"
                          fill="#636978"
                        />
                        <path
                          d="M19.9747 8.05762H12.5059V17.5006H19.9747C20.9029 17.5006 21.6553 16.7482 21.6553 15.8201V9.73818C21.6553 8.81001 20.9029 8.05762 19.9747 8.05762Z"
                          fill="#414952"
                        />
                        <path
                          d="M6.62988 15.2417H18.3698V17.5006H6.62988V15.2417Z"
                          fill="#414952"
                        />
                        <path
                          d="M12.5059 15.2417H18.37V17.5006H12.5059V15.2417Z"
                          fill="#23272B"
                        />
                        <path
                          d="M6.62988 3.53369H18.3698V10.1832H6.62988V3.53369Z"
                          fill="#414952"
                        />
                        <path
                          d="M12.5059 3.53369H18.37V10.1832H12.5059V3.53369Z"
                          fill="#23272B"
                        />
                        <path
                          d="M15.6268 6.69824H9.37363C8.8 6.69824 8.33496 6.2332 8.33496 5.65957V3.53369H16.6654V5.65957C16.6654 6.2332 16.2004 6.69824 15.6268 6.69824Z"
                          fill="#555A66"
                        />
                        <path
                          d="M12.5059 3.53369V6.69824H15.6265C16.2001 6.69824 16.6652 6.2332 16.6652 5.65957V3.53369H12.5059Z"
                          fill="#333940"
                        />
                        <path
                          d="M4.94385 13.4062H20.0562V14.6593H4.94385V13.4062Z"
                          fill="#555A66"
                        />
                        <path
                          d="M12.5059 13.4062H20.0562V14.6593H12.5059V13.4062Z"
                          fill="#333940"
                        />
                        <path
                          d="M5.67393 12.6932C6.07714 12.6932 6.404 12.3663 6.404 11.9631C6.404 11.5599 6.07714 11.233 5.67393 11.233C5.27071 11.233 4.94385 11.5599 4.94385 11.9631C4.94385 12.3663 5.27071 12.6932 5.67393 12.6932Z"
                          fill="#64E1DC"
                        />
                        <path
                          d="M7.60459 12.6932C8.0078 12.6932 8.33467 12.3663 8.33467 11.9631C8.33467 11.5599 8.0078 11.233 7.60459 11.233C7.20138 11.233 6.87451 11.5599 6.87451 11.9631C6.87451 12.3663 7.20138 12.6932 7.60459 12.6932Z"
                          fill="#64E1DC"
                        />
                        <path
                          d="M7.67773 15.2417H17.3228V21.4775H7.67773V15.2417Z"
                          fill="white"
                        />
                        <path
                          d="M12.5059 15.2417H17.3225V21.4775H12.5059V15.2417Z"
                          fill="#E9EDF5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_30_808">
                          <rect width="25" height="25" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
              <LatestBlogsContentsComponent />
             
              <LatestBlogsInnerComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestBlogsInner;
