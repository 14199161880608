import React, { useState } from "react";
import "./footerComponent.scss";
import { CiMail } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import axios from "axios";
const FooterComponent = ({ socialLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mail, setMail] = useState(true);

  const openModal = () => setIsOpen(true);
  const closeModal = async () => {
    try {
      const reqBody = {
        email: mail,
      };
      const postNewsLetter = await axios.post(
        "https://new.ecstaticexplorers.com/api/news-letter",
        reqBody
      );
      if (postNewsLetter) {
        alert(`${mail} added successfully.`);
        setIsOpen(false);
      }
    } catch (err) {
      alert(`Something went wrong.`);
    } finally {
    }
  };

  return (
    <div className="travel-container foot-con">
      <div className="footer-alignment alignment">
        <div className="footer">
          <div className="footer-first-row">
            <div className="f1">
              <h4>Join Our Mailing List</h4>
              <p>
                Get regular updates about upcoming events, trip planning advice
                and compelling stories.
              </p>
              <button onClick={() => openModal()}>
                <CiMail size={28} /> Sign up for our E-Newsletter
              </button>
              <div className="footer-social">
                {socialLinks.map((item, index) => {
                  return (
                    <span>
                      {item.name == "Instagram" ? (
                        <a href={item.link}>
                          {" "}
                          <FaInstagram size={23} />
                        </a>
                      ) : item.name == "Facebook" ? (
                        <a target="blank" href={item.link}>
                          <FaFacebookF
                            style={{ background: "#4A6EA9" }}
                            size={23}
                          />
                        </a>
                      ) : (
                        " "
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="f2">
              <h4>Quick Links</h4>
              <a href="/">
                <p>Home</p>
              </a>
              <a href="/about-us">
                <p>About Us</p>
              </a>
              <a href="/contact-us">
                <p>Contact Us</p>
              </a>
              <a style={{ textDecoration: "none" }} href="/latest-blogs">
                <p>Latest Blogs</p>
              </a>
              {/* <a style={{ textDecoration: "none" }} href="/news-letter">
                <p>Newsletter</p>
              </a> */}
              
            </div>
            <div className="f3">
              <h4>Other Popular Tours</h4>
              <a href="/package">
                <p>North Bengal Offbeat</p>
              </a>
              <a href="/package">
                <p>Himachal</p>
              </a>
              <a href="/package">
                <p>Kashmir</p>
              </a>
              <a href="/package">
                <p>Leh Ladakh</p>
              </a>
              <a href="/package">
                <p>Sikkim</p>
              </a>
              <a href="/package">
                <p>Weekend Gateway</p>
              </a>
            </div>
          </div>
          <div className="footer-second-row">
          <div className="fot">
              <a href="/privacy-policies">
                <p>Privacy Policy</p>
              </a>{" "}
              <a href="/terms-and-conditions">
                <p>Terms & Conditions</p>
              </a>
            </div>
            <p className="yui">
              Copyright © 2024 Ecstatic Explorers - All Rights Reserved
            </p>
            <span>
            <a href="https://wa.me/1234567890?text=Hello%20there!" target="_blank">  <FaWhatsapp /></a>

            
              <a target="blank" href="tel:+1234567890"><IoIosCall className="call-icon" /></a>

              
            </span>
          </div>
        </div>
      </div>
      <div className="modal-wrapper">
        {isOpen && (
          <div
            className="modal-overlay"
            onClick={(e) => {
              // e.stopPropagation()
            }}
          >
            <div className="modal-content">
              <button
                className="close-button"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                &times;
              </button>{" "}
              <h2>News Letter</h2>
              <p>Enter your email address</p>
              <input
                className="news-input"
                type="email"
                onChange={(e) => setMail(e.target.value)}
              />
              <button className="close-modal-btn" onClick={() => closeModal()}>
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FooterComponent;
