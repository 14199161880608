import React, { useEffect, useRef, useState } from "react";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import PkgRoundSliderComponent from "../Components/PkgRoundSliderComponent/PkgRoundSliderComponent";
import PkgFilterComponent from "../Components/PkgFilterComponent/PkgFilterComponent";
import PkgCardComponent from "../Components/PkgCardComponent/PkgCardComponent";
import PkgFilterSearchComponent from "../Components/PkgFilterSearchComponent/PkgFilterSearchComponent";
import ProgressItemComponent from "../Components/ProgressItemComponent/ProgressItemComponent";
import axios from "axios";
import GalleryButton from "../Components/GalleryRoundComponent/GalleryRoundComponent";
import DayNinghComponent from "../Components/DayNinghComponent copy/DayNinghComponent";

const Package = () => {
  const pkgItemsRef = useRef(null);
  const [translateX, setTranslateX] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [visibleCards, setVisibleCards] = useState(4);
  const [pakage, setPakage] = useState([]);
  const [pakageBkp, setPakageBkp] = useState([]);
  const [pakageCount, setPakageCount] = useState(0);
  const [categoryPackage, setCategoryPackage] = useState([]);
  // const [minBudget, setMinBudget] = useState([]);
  // const [budgetRange, setBudgetRange] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const res = await axios.get("https://new.ecstaticexplorers.com/api/pakages");
      const categoryPackage = await axios.get(
        "https://new.ecstaticexplorers.com/api/categories"
      );
      let newArr = [];
      categoryPackage.data.data.map((item, index) => {
        newArr.push({
          name: item.name,
          count: item.pakages.length,
          pakage_cities: [],
          pakage_tags: [],
          ...item,
        });
      });

      setCategoryPackage(newArr);
      setPakageBkp(res.data.data);
      setPakageCount(res.data.data.length);
      setPakage(res.data.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSlide = (direction) => {
    const container = pkgItemsRef.current;
    const containerWidth = container.offsetWidth;
    const itemsWidth = container.scrollWidth;

    const maxTranslateX = -(itemsWidth - containerWidth);

    const moveDistance = containerWidth / 3;

    if (direction === "right" && translateX > maxTranslateX) {
      setTranslateX((prev) => Math.max(prev - moveDistance, maxTranslateX));
    } else if (direction === "left" && translateX < 0) {
      setTranslateX((prev) => Math.min(prev + moveDistance, 0));
    }
  };

  const dropdownOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleViewMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 4);
  };

  const handleCityClick = (cities) => {
    const filteredPackages = pakageBkp.filter((pkg) =>
      pkg.pakage_cities.some((pkgCity) => cities.includes(pkgCity.city_id))
    );
    setPakage(filteredPackages);
  };
const handleDayNightClick =(minVal, maxVal)=>{

}
  const handleBudgetClick = (selectedRange, minVal, maxVal) => {
    console.log("BUNDLE:", selectedRange, minVal, maxVal);
    // setBudgetRange(selectedRange);
    // setMinBudget(minVal);
    // setMaxBudget(maxVal);

    const filteredPackages = pakageBkp.filter((pkg) => {
      console.log("kkkkkkkkkkk:", pkg.total_price);
      if (selectedRange.above40k) {
        return pkg.total_price > 40000;
      }
      if (selectedRange.below25k) {
        return pkg.total_price < 250000;
      }
      if (selectedRange.between25k35k) {
        return 25000 < pkg.total_price < 350000;
      }
      if (selectedRange.between35k40k) {
        return 35000 < pkg.total_price < 40000;
      } else {
        return minVal < pkg.total_price && pkg.total_price < maxVal;
      }
    });
    setPakage(filteredPackages);
  };
  const handleCategoryTagData = (pkg) => {
    console.log("PKGHG HG:", pkg.pakages);
    setPakage(pkg.pakages);
  };

  return (
    <>
      <div className="travel-container">
        <div className="alignment">
          <ProgressItemComponent />

          <div className="btn-g">
            <div className="g-round">
              <GalleryButton />
            </div>
          </div>

          <PkgRoundSliderComponent />
        </div>
      </div>

      <div className="green-bar-sec"></div>

      <div className="travel-container">
        <div className="alignment">
          <div className="pkg-Container">
            <div className="pkg-filter">
              <div className="pkg-filter-header">
                <h4>Filters</h4>
              </div>
              <div className="filter-item">
              <DayNinghComponent handleClick={handleDayNightClick} />
                <PkgFilterComponent handleClick={handleBudgetClick} />
                {/* <PkgFilterComponent /> */}
                <PkgFilterSearchComponent handleClick={handleCityClick} />
              </div>
            </div>

            <div className="pkg-contain">
              <div className="pkg-contain-header">
                <div className="pkgs-item">
                  <div
                    className="pkg-btn-items"
                    ref={pkgItemsRef}
                    style={{ transform: `translateX(${translateX}px)` }}
                  >
                    <button
                      className="pkg-item-btn-active"
                      onClick={() => {
                        fetchData();
                      }}
                    >
                      All Packages <span>({pakageCount})</span>
                    </button>
                    {categoryPackage.map((pkg, index) => (
                      <button
                        className={
                          pakage.id == pkg.id ? "pkg-item-btn-active" : ""
                        }
                        key={index}
                        onClick={() => {
                          handleCategoryTagData(pkg);
                        }}
                      >
                        {pkg.name}
                        <span>({pkg.count})</span>
                      </button>
                    ))}
                  </div>
                </div>

                <div className="navigation">
               
                  <svg onClick={() => handleSlide("left")} width="20" height="20" id="sp-left" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.68747 7.50003C4.68741 7.43847 4.69951 7.3775 4.72308 7.32062C4.74665 7.26375 4.78122 7.21209 4.82481 7.16862L9.51231 2.48112C9.69548 2.29796 9.99208 2.29796 10.1751 2.48112C10.3582 2.66429 10.3583 2.96089 10.1751 3.14394L5.81903 7.50003L10.1751 11.8561C10.3583 12.0393 10.3583 12.3359 10.1751 12.5189C9.99196 12.702 9.69536 12.7021 9.51231 12.5189L4.82481 7.83144C4.78122 7.78796 4.74665 7.73631 4.72308 7.67943C4.69951 7.62256 4.68741 7.56159 4.68747 7.50003Z" fill="#9E9E9E"/>
</svg>

                 


                  <svg id="sp-right"  onClick={() => handleSlide("right")} width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3125 7.49997C10.3126 7.56153 10.3005 7.6225 10.2769 7.67938C10.2534 7.73625 10.2188 7.78791 10.1752 7.83138L5.48769 12.5189C5.30452 12.702 5.00792 12.702 4.82487 12.5189C4.64183 12.3357 4.64171 12.0391 4.82487 11.8561L9.18097 7.49997L4.82487 3.14388C4.64171 2.96071 4.64171 2.66411 4.82487 2.48106C5.00804 2.29802 5.30464 2.2979 5.48769 2.48106L10.1752 7.16856C10.2188 7.21204 10.2534 7.26369 10.2769 7.32057C10.3005 7.37744 10.3126 7.43841 10.3125 7.49997Z" fill="#63AB45"/>
</svg>



                </div>
              </div>

              <div className="short-drop">
                <p>
                  Sort By:
                  <button id="short" onClick={toggleDropdown}>
                    Popular
                    <svg
                      width="15"
                      height="15"
                      className={isDropdownOpen ? "active" : ""}
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.50003 10.3125C7.43847 10.3126 7.3775 10.3005 7.32062 10.2769C7.26375 10.2534 7.21209 10.2188 7.16862 10.1752L2.48112 5.48769C2.29796 5.30452 2.29796 5.00792 2.48112 4.82487C2.66429 4.64183 2.96089 4.64171 3.14394 4.82487L7.50003 9.18097L11.8561 4.82487C12.0393 4.64171 12.3359 4.64171 12.5189 4.82487C12.702 5.00804 12.7021 5.30464 12.5189 5.48769L7.83144 10.1752C7.78796 10.2188 7.73631 10.2534 7.67943 10.2769C7.62256 10.3005 7.56159 10.3126 7.50003 10.3125Z"
                        fill="#63AB45"
                      />
                    </svg>
                  </button>
                </p>

                {isDropdownOpen && (
                  <div className="dropdown-content">
                    <ul>
                      {dropdownOptions.map((option, index) => (
                        <li key={index}>{option.label}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="pkg-card-container">
                {/* {Array.from({ length: visibleCards }).map((_, index) => ( */}

                {pakage.map((item, index) => {
                  return <PkgCardComponent item={item} />;
                })}

                {/* ))} */}

                {visibleCards < 20 && (
                  <button id="viewmore" onClick={handleViewMore}>
                    View More
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Package;
