import React from "react";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import ExploreByStatesComponent from "../Components/ExploreByStatesComponent/ExploreByStatesComponent";
import SellingPackageComponent from "../Components/SellingPackageComponent/SellingPackageComponent";
import ExploreUnexpectedComponent from "../Components/ExploreUnexpectedComponent/ExploreUnexpectedComponent";
import RatingComponent from "../Components/RatingComponent/RatingComponent";
import ExploreHolidaysComponent from "../Components/ExploreHolidaysComponent/ExploreHolidaysComponent";
import InstaComponent from "../Components/InstaComponent/InstaComponent";
import GooglePopComponent from "../Components/GooglePopComponent/GooglePopComponent";

const Homepage = () => {
  return (
    <div>

    
<div className="travel-container">



      <div className="alignment">
<div className="g-pop">
<div className="google_pop">
<GooglePopComponent /></div>
</div>
        <ExploreByStatesComponent />
        <SellingPackageComponent />
        <ExploreHolidaysComponent />
        <ExploreUnexpectedComponent />
        <RatingComponent />
        <InstaComponent />
      </div>

</div>

    </div>
  );
};

export default Homepage;
