import React, { useEffect, useState } from "react";
import "./latestBlogsInnerComponent.scss";
import { FaShareFromSquare } from "react-icons/fa6";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { IoMdMail, IoMdPrint } from "react-icons/io";
import axios from "axios";

const LatestBlogsInnerComponent = () => {
  const [blogDtels, setBlogDtels] = useState([]);

  useEffect(() => {
    const blogId = window.location.search.replace("?", "");
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://new.ecstaticexplorers.com/api/blog/${blogId}`
        );
        setBlogDtels(res.data.data);
        sessionStorage.setItem(
          "blog_tags",
          JSON.stringify(res.data.data.blog_tags)
        );
      } catch (err) {
        throw err;
      } finally {
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container-blog-inner">
      <div className="latestBlogs-inner-component">
        <h1>{blogDtels.title}</h1>
        <div className="latest-blogs-inner-head">
          <p>
            <span>By:</span> Lorem Ipsum
          </p>
          <div className="latest-blogs-inner-top">
            <p>
              <span>Last updated:</span> 2024/05/29 at 10:21 AM
            </p>
            <div className="latest-blogs-inner-share">
              <FaShareFromSquare className="sent" />
              <p>share</p>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL"
                target="_blank"
              >
                <FaFacebookF />
              </a>
              <a href="https://www.instagram.com/direct/inbox/" target="_blank">
                {" "}
                <FaInstagram />
              </a>

              <a
                href="https://wa.me/?text=Check%20out%20this%20cool%20article!"
                target="_blank"
              >
                <FaWhatsapp />
              </a>

              <a
                href="https://new.ecstaticexplorers.com/api/blog/"
                target="_blank"
              >
                <IoMdMail />
              </a>

              <a href="https://imgur.com/yourimage" target="_blank">
                <IoMdPrint />
              </a>

              <h5> | 22 Min Read</h5>
            </div>
          </div>
        </div>
        <div className="latest-blogs-inner-img">
          <img
            src={
              "https://tse3.mm.bing.net/th?id=OIP.eOSbibdrocNuNhOfGxDDJgHaE8&pid=Api&P=0&h=180"
            }
            // src={blogDtels.file_path}
            alt=""
          ></img>
        </div>
        <p className="hero">
          <span>
            <svg
              width="52"
              height="1"
              viewBox="0 0 52 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="52" y2="0.5" stroke="black" />
            </svg>
          </span>
          {blogDtels.text}
        </p>
        <p
          className="latest-blogs-inner"
          dangerouslySetInnerHTML={{ __html: blogDtels.description }}
        ></p>
      </div>
    </div>
  );
};

export default LatestBlogsInnerComponent;
