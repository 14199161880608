import React, { useEffect, useState } from "react";
import "./sellingPackageComponent.scss";
import axios from "axios";
import CardComponent from "../CardComponent/CardComponent"; 

const SellingPackageComponent = ({
  title = "Most Popular Packages", 
  exploreUnexpectedData = [], 
}) => {
  const [states, setStates] = useState([]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://new.ecstaticexplorers.com/api/top-selling-pakages"
        );
        
        const filteredData = res.data.data.map((item) => ({
          name: item.title, 
          file_path: item.pakage_file_path, 
        }));

        setStates(filteredData); 
      } catch (err) {
        console.error(err); 
      }
    };

    fetchData();
  }, []); 

  return (
    <div className="explore-cont">
      <h1>{title}</h1>
      <div className="top-selling">
        {!exploreUnexpectedData.length ? (
          <>
            <h2>Jackpot Deals on Top Selling Packages</h2>
            <p className="p-tag">Save extra with our exclusive deals!</p>
          </>
        ) : null}
        <div className="carousel-parent">
          <CardComponent
            allStates={exploreUnexpectedData.length ? exploreUnexpectedData : states}
            isDescNeeded={exploreUnexpectedData.length ? true : false}
            isExploreUnexpected={exploreUnexpectedData.length ? true : false}
            parentCont="package"
            isMostPopularPakage={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SellingPackageComponent;
