import React, { useEffect, useState } from "react";
import "./packageDataComponent.scss";

const PackageDataComponent = (props) => {
  const [sections, setSections] = useState([
    {
      id: 1,
      day: "Day 1",
      title: "Jaipur: Warm Welcome in Pink City",
      checked: false,
      isOpen: false,
    },
    {
      id: 2,
      day: "Day 2",
      title: "Exploring Jaipur Heritage",
      checked: false,
      isOpen: false,
    },
    {
      id: 3,
      day: "Day 3",
      title: "Exploring Jaipur Heritage",
      checked: false,
      isOpen: false,
    },
    
    {
      id: 4,
      day: "Day 3",
      title: "Exploring Jaipur Heritage",
      checked: false,
      isOpen: false,
    },
    {
      id: 5,
      day: "Day 5",
      title: "Exploring Jaipur Heritage",
      checked: false,
      isOpen: false,
    },
    
  ]);
  const [activeTag, setActiveTag] = useState(1);
  const [tourType, setTourType] = useState("Tour Itinerary");
  const [tourDescription, setTourDescription] = useState("");

  const toggleCollapse = (id) => {
    setSections(
      sections.map((section) =>
        section.id === id
          ? {
              ...section,
              isOpen: !section.isOpen,
              checked: !section.isOpen ? true : section.checked,
            }
          : { ...section, isOpen: false, checked: false }
      )
    );
  };

  const imageUrl =
    "https://images.unsplash.com/photo-1557456170-0cf4f4d0d362?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bGFrZXxlbnwwfHwwfHx8MA%3D%3D";

  useEffect(() => {
    handlePkgTags(activeTag);
  }, [props.standard]);

  const handlePkgTags = (val) => {
    setActiveTag(val);
    switch (val) {
      case 1:
        setTourType("Tour Itinerary");
        setTourDescription(
          props.pakageData.pakage_itineraries.map((item, index) => {
            if (item.type.id == props.standard) return item.tour_itinerary;
          })
        );
        break;
      case 2:
        setTourType("Tour inclusion");
        setTourDescription(props.pakageData.tour_inclusion);

        break;
      case 3:
        setTourType("Tour Exclusion");
        setTourDescription(props.pakageData.tour_exclusion);

        break;
      case 4:
        setTourType("Booking Policy");
        setTourDescription(props.pakageData.booking_policy);

        break;
      case 5:
        setTourType("Cancel Policy");
        setTourDescription(props.pakageData.cancellation_policy);

        break;

      default:
        break;
    }
  };

  return (
    <div className="pkg-deta">
      <div className="pkg-data-header">
        <div class="pkg-heder-options-section">
          <button
            className={activeTag == 1 ? "pkg-btn-active" : ""}
            onClick={() => handlePkgTags(1)}
          >
            Tour Itinerary
          </button>
          <button
            className={activeTag == 2 ? "pkg-btn-active" : ""}
            onClick={() => handlePkgTags(2)}
          >
            Tour inclusion
          </button>
          <button
            className={activeTag == 3 ? "pkg-btn-active" : ""}
            onClick={() => handlePkgTags(3)}
          >
            Tour Exclusion
          </button>
          <button
            className={activeTag == 4 ? "pkg-btn-active" : ""}
            onClick={() => handlePkgTags(4)}
          >
            Booking Policy
          </button>
          <button
            className={activeTag == 5 ? "pkg-btn-active" : ""}
            onClick={() => handlePkgTags(5)}
          >
            Cancel Policy
          </button>
        </div>
      </div>
      <div className="pkg-data-info">
        <h2>{tourType}</h2>
        {tourType == "Tour Itinerary" ? (
          <p dangerouslySetInnerHTML={{ __html: tourDescription }} />
        ) : (
          <p dangerouslySetInnerHTML={{ __html: tourDescription }} />
        )}
      </div>
      <div className="pkg-data-conain">
        <span className="line"></span>
        {sections.map((section) => (
          <div
            key={section.id}
            className={`pkg-dropdown ${section.isOpen ? "active-line" : ""}`}
          >
            <h4>
              {section.day}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label className="custom-checkbox">
                <input type="checkbox" checked={section.checked} readOnly />
                <span
                  className={`checkmark ${section.checked ? "checked" : ""}`}
                ></span>
              </label>
              {section.title}
            </h4>
            <span className="togle">
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`collapse-btn ${
                  section.isOpen ? "collapse-active" : ""
                }`}
                onClick={() => toggleCollapse(section.id)}
              >
                <path
                  d="M7.50003 10.3125C7.43847 10.3126 7.3775 10.3005 7.32062 10.2769C7.26375 10.2534 7.21209 10.2188 7.16862 10.1752L2.48112 5.48769C2.29796 5.30452 2.29796 5.00792 2.48112 4.82487C2.66429 4.64183 2.96089 4.64171 3.14394 4.82487L7.50003 9.18097L11.8561 4.82487C12.0393 4.64171 12.3359 4.64171 12.5189 4.82487C12.702 5.00804 12.7021 5.30464 12.5189 5.48769L7.83144 10.1752C7.78796 10.2188 7.73631 10.2534 7.67943 10.2769C7.62256 10.3005 7.56159 10.3126 7.50003 10.3125Z"
                  fill="#63AB45"
                />
              </svg>
            </span>
            <div className="pkg-data-btn">
              <button>Check-In</button>
              <button>Site Seeing</button>
            </div>
            {section.isOpen && (
              <div className={`collapse ${section.isOpen ? "open" : ""}`}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <h5>
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 5V19M3 16H21M21 19V13.2C21 12.0799 21 11.5198 20.782 11.092C20.5903 10.7157 20.2843 10.4097 19.908 10.218C19.4802 10 18.9201 10 17.8 10H11V15.7273M7 12H7.01M8 12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11C7.55228 11 8 11.4477 8 12Z"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Stay At
                </h5>
                <h4>Check-In at Deluxe Hotel in Lorem Ipsum</h4>
                <div className="pkg-data-img">
                  <div className="pkg-data-img-box">
                    <img src={imageUrl} alt="img" />
                    <p>Lorem Ipsum is dummy text</p>
                  </div>
                  <div className="pkg-data-img-box">
                    <img src={imageUrl} alt="img" />
                    <p>Lorem Ipsum is dummy text</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackageDataComponent;
