import "./communicationComponent.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

const CommunicationComponent = () => {
  return (
    <>
      <div className="contact-card">
        <div className="section">
          <div className="label">Phone</div>
          <div className="contact-info call">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5 0.09375C13.2552 0.09375 10.0832 1.05596 7.38518 2.8587C4.68719 4.66144 2.58436 7.22375 1.34261 10.2216C0.100859 13.2194 -0.22404 16.5182 0.408999 19.7007C1.04204 22.8832 2.60458 25.8065 4.89904 28.101C7.19349 30.3954 10.1168 31.958 13.2993 32.591C16.4818 33.224 19.7806 32.8991 22.7784 31.6574C25.7763 30.4156 28.3386 28.3128 30.1413 25.6148C31.944 22.9168 32.9063 19.7449 32.9063 16.5C32.9063 12.1488 31.1777 7.9758 28.101 4.89903C25.0242 1.82226 20.8512 0.09375 16.5 0.09375ZM17.2033 10.1716C18.6946 10.1733 20.1244 10.7665 21.179 11.8211C22.2335 12.8756 22.8267 14.3054 22.8284 15.7967H21.4219C21.4207 14.6782 20.9759 13.6059 20.185 12.815C19.3941 12.0241 18.3218 11.5793 17.2033 11.5781V10.1716ZM21.7588 26.3438C21.7161 26.3438 17.4931 26.2716 12.0627 20.9373C6.72844 15.5091 6.65626 11.2839 6.65626 11.2413C6.65626 10.7152 9.93751 6.65625 10.6627 6.65625C10.9142 6.65625 11.11 6.82687 11.3244 7.08281C11.8406 7.6975 14.1233 11.3003 14.0183 11.9008C13.968 12.183 13.7284 12.3973 13.0416 12.9738C12.7031 13.2398 12.3864 13.5324 12.0944 13.8488C12.2342 14.4276 12.442 14.9878 12.7134 15.5178C13.7247 17.597 15.4048 19.2763 17.4844 20.2866C18.0144 20.558 18.5746 20.7658 19.1534 20.9056C19.4698 20.6136 19.7624 20.2969 20.0284 19.9584C20.6049 19.2716 20.8192 19.032 21.1014 18.9817C21.7019 18.8723 25.3047 21.1594 25.9194 21.6756C26.1753 21.8944 26.3459 22.0858 26.3459 22.3373C26.3438 23.0625 22.2849 26.3438 21.7588 26.3438ZM24.9372 15.7967C24.9349 13.7463 24.1193 11.7805 22.6694 10.3306C21.2195 8.88069 19.2537 8.06513 17.2033 8.06281V6.65625C19.6266 6.65914 21.9498 7.62308 23.6634 9.33663C25.3769 11.0502 26.3409 13.3734 26.3438 15.7967H24.9372Z"
                fill="#008000"
              />
            </svg>
            <div className="phone-box">
              <div className="contact-item">
                <span>+91 89108 45933</span>
              </div>
              <div className="contact-item">
                <span>+91 98361 55825</span>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="label">Email</div>
          <div className="contact-info">
            <div className="contact-item">
              <svg
                width="33"
                height="33"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.5 0C27.165 0 35 7.83501 35 17.5C35 27.165 27.165 35 17.5 35C7.83501 35 0 27.165 0 17.5C0 7.83501 7.83501 0 17.5 0ZM28.6084 24.2841V11.2778L22.1049 17.7809L28.6084 24.2841ZM7.78094 24.549H27.2195L21.2785 18.608L19.0142 20.872C18.9044 20.9815 18.7557 21.0429 18.6007 21.0429H16.3995C16.3227 21.0429 16.2466 21.0279 16.1757 20.9986C16.1047 20.9692 16.0402 20.9262 15.9858 20.872L13.7216 18.608L7.78066 24.549H7.78094ZM6.3916 11.2774V24.2844L12.8951 17.7809L6.3916 11.2774ZM27.7817 10.451H7.21875L16.6417 19.8739H18.3587L27.7817 10.451Z"
                  fill="#008000"
                />
              </svg>

              <span>explore@ecstaticexplorers.com</span>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="label">Location</div>
          <div className="contact-info">
            <div className="contact-item location-contact">
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_198_2151)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.5 35C7.83656 35 0 27.1634 0 17.5C0 7.83656 7.83656 0 17.5 0C27.1634 0 35 7.83656 35 17.5C35 27.1634 27.1634 35 17.5 35ZM17.5 6.11728C22.2873 6.11728 26.168 9.99801 26.168 14.7853C26.168 19.5724 17.5 28.8826 17.5 28.8826C17.5 28.8826 8.83199 19.5724 8.83199 14.7853C8.83199 9.99801 12.7127 6.11728 17.5 6.11728ZM17.5 11.3414C19.315 11.3414 20.787 12.8133 20.787 14.6285C20.787 16.4435 19.315 17.9155 17.5 17.9155C15.685 17.9155 14.213 16.4435 14.213 14.6285C14.213 12.8133 15.685 11.3414 17.5 11.3414Z"
                    fill="#008000"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_198_2151">
                    <rect width="35" height="35" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span>
                Premises no. D-206, Mondal Ganthi, Arjunpur, Kaikhali, North
                Dumdum, West Bengal, India. Kolkata -700052
              </span>
            </div>
          </div>
        </div>

        <div className="message-section section">
          <h4>We'd love to hear from you!</h4>
          <p>
            Have a question about our services? Want to see if your particular
            vacation is one we can help with? We'd love to hear from you!
          </p>
          <div className="whatsapp">
            
          <svg width="33" height="33" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z" fill="#29A71A"/>
<path d="M25.2159 9.78406C23.3951 7.94517 20.9772 6.81743 18.3982 6.60428C15.8192 6.39112 13.249 7.10657 11.1511 8.62159C9.05312 10.1366 7.56563 12.3514 6.95689 14.8666C6.34815 17.3818 6.65823 20.0317 7.8312 22.3383L6.67978 27.9284C6.66783 27.984 6.6675 28.0415 6.67879 28.0973C6.69008 28.1531 6.71276 28.2059 6.74541 28.2525C6.79323 28.3233 6.8615 28.3777 6.9411 28.4086C7.02069 28.4396 7.10783 28.4455 7.19086 28.4255L12.6696 27.127C14.9697 28.2702 17.6009 28.5604 20.095 27.9458C22.589 27.3311 24.7841 25.8516 26.2896 23.7705C27.7952 21.6893 28.5136 19.1415 28.317 16.5804C28.1203 14.0193 27.0215 11.611 25.2159 9.78406ZM23.5076 23.4182C22.2479 24.6744 20.6257 25.5036 18.8697 25.789C17.1136 26.0744 15.3123 25.8016 13.7196 25.0091L12.9559 24.6312L9.59711 25.4267L9.60705 25.3849L10.3031 22.0042L9.92921 21.2664C9.11538 19.6681 8.82829 17.8532 9.10907 16.0818C9.38985 14.3103 10.2241 12.6731 11.4923 11.4048C13.0858 9.81179 15.2467 8.91689 17.5 8.91689C19.7532 8.91689 21.9141 9.81179 23.5076 11.4048C23.5212 11.4204 23.5358 11.435 23.5514 11.4486C25.1251 13.0457 26.0037 15.2002 25.9955 17.4424C25.9873 19.6846 25.093 21.8326 23.5076 23.4182Z" fill="white"/>
<path d="M23.2097 20.9383C22.798 21.5866 22.1477 22.3801 21.3304 22.5769C19.8986 22.923 17.7012 22.5889 14.9668 20.0394L14.933 20.0096C12.5287 17.7803 11.9043 15.9249 12.0554 14.4534C12.1389 13.6181 12.835 12.8624 13.4216 12.3693C13.5144 12.2901 13.6243 12.2337 13.7428 12.2047C13.8612 12.1756 13.9848 12.1747 14.1036 12.202C14.2225 12.2292 14.3333 12.2839 14.4272 12.3616C14.5212 12.4394 14.5956 12.538 14.6446 12.6497L15.5296 14.6383C15.5871 14.7672 15.6084 14.9094 15.5912 15.0495C15.5741 15.1897 15.5191 15.3225 15.4321 15.4338L14.9847 16.0144C14.8887 16.1343 14.8307 16.2802 14.8183 16.4333C14.8059 16.5864 14.8396 16.7397 14.9151 16.8735C15.1656 17.313 15.7662 17.9593 16.4324 18.5579C17.1801 19.234 18.0094 19.8525 18.5344 20.0633C18.6749 20.1207 18.8293 20.1347 18.9778 20.1035C19.1263 20.0723 19.2621 19.9974 19.3676 19.8883L19.8867 19.3653C19.9868 19.2665 20.1114 19.1961 20.2476 19.1612C20.3839 19.1262 20.5269 19.128 20.6622 19.1664L22.7642 19.763C22.8802 19.7986 22.9865 19.8602 23.0749 19.9431C23.1634 20.0261 23.2318 20.1282 23.2748 20.2416C23.3177 20.355 23.3342 20.4767 23.323 20.5975C23.3117 20.7182 23.2729 20.8348 23.2097 20.9383Z" fill="white"/>
</svg>

            <span>Message us on WhatsApp</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunicationComponent;
