import React, { useState, useEffect, useRef } from "react";
import "./pkgRoundSliderComponent.scss";
import axios from "axios";

const PkgRoundSliderComponent = () => {
  const [position, setPosition] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const [raCard, setRaCard] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://new.ecstaticexplorers.com/api/top-destination"
        );
        setRaCard(res.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };
    fetchData();
  }, []);

  const cardWidth = 167;
  const visibleCards = 5;
  const totalCards = 10;
  const cards = Array.from({ length: totalCards }, (_, idx) => ({
    id: idx,
    image: "https://etimg.etb2bimg.com/photo/94676301.cms",
    title: "Udaipur",
  }));

  const sliderRef = useRef(null);

  const slideRight = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setPosition((prevPosition) => prevPosition - cardWidth);
    }
  };

  const slideLeft = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setPosition((prevPosition) => prevPosition + cardWidth);
    }
  };

  useEffect(() => {
    const handleTransitionEnd = () => {
      setIsTransitioning(false);
      if (Math.abs(position) >= cardWidth * totalCards) {
        setPosition(0);
      }
      if (position > 0) {
        setPosition(-cardWidth * (totalCards - visibleCards));
      }
    };

    const sliderElement = sliderRef.current;
    sliderElement.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      sliderElement.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [position, totalCards, visibleCards]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      slideRight();
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="round-slider-container">
      <h1>Top Destinations</h1>
      <div className="circle-slider">
        <div
          ref={sliderRef}
          className="round-slider"
          style={{
            transform: `translateX(${position}px)`,
            transition: isTransitioning ? "transform 0.5s ease" : "none",
          }}
        >
          {/* {cards.slice(-visibleCards).map((card) => (
            <div className="round-slider-card" key={`clone-start-${card.id}`}>
              <img src={card.image} alt="img" />
              <p>{card.title}</p>
            </div>
          ))} */}
          {raCard.map((card) => (
            <div className="round-slider-card" key={card.id}>
              <img src={card.file_path} alt="img" />
              <p>{card.name}</p>
            </div>
          ))}
          {/* {cards.slice(0, visibleCards).map((card) => (
            <div className="round-slider-card" key={`clone-end-${card.id}`}>
              <img src={card.image} alt="img" />
              <p>{card.title}</p>
            </div>
          ))} */}
        </div>
      </div>
      <div className="slider-btn">
        <svg
          width="25"
          height="25"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="swap-left"
          onClick={slideLeft}
        >
          <path
            d="M17.0303 8.46975L11.7803 3.21975C11.6388 3.08313 11.4493 3.00754 11.2527 3.00924C11.0561 3.01095 10.8679 3.08983 10.7289 3.22889C10.5898 3.36794 10.511 3.55605 10.5092 3.7527C10.5075 3.94935 10.5831 4.1388 10.7198 4.28025L14.6895 8.25H1.5C1.30109 8.25 1.11032 8.32902 0.96967 8.46967C0.829018 8.61032 0.75 8.80109 0.75 9C0.75 9.19891 0.829018 9.38968 0.96967 9.53033C1.11032 9.67098 1.30109 9.75 1.5 9.75H14.6895L10.7198 13.7197C10.6481 13.7889 10.591 13.8717 10.5517 13.9632C10.5124 14.0547 10.4917 14.1531 10.4908 14.2527C10.4899 14.3523 10.5089 14.451 10.5466 14.5432C10.5843 14.6354 10.64 14.7191 10.7105 14.7895C10.7809 14.86 10.8646 14.9157 10.9568 14.9534C11.049 14.9911 11.1477 15.0101 11.2473 15.0092C11.3469 15.0083 11.4453 14.9876 11.5368 14.9483C11.6283 14.909 11.7111 14.8519 11.7803 14.7803L17.0303 9.53025C17.1709 9.3896 17.2498 9.19887 17.2498 9C17.2498 8.80113 17.1709 8.6104 17.0303 8.46975Z"
            fill="#008000"
            fill-opacity="0.6"
          />
        </svg>

        <svg
          id="swap-right"
          width="25"
          height="25"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={slideRight}
        >
          <path
            d="M17.0303 8.46975L11.7803 3.21975C11.6388 3.08313 11.4493 3.00754 11.2527 3.00924C11.0561 3.01095 10.8679 3.08983 10.7289 3.22889C10.5898 3.36794 10.511 3.55605 10.5092 3.7527C10.5075 3.94935 10.5831 4.1388 10.7198 4.28025L14.6895 8.25H1.5C1.30109 8.25 1.11032 8.32902 0.96967 8.46967C0.829018 8.61032 0.75 8.80109 0.75 9C0.75 9.19891 0.829018 9.38968 0.96967 9.53033C1.11032 9.67098 1.30109 9.75 1.5 9.75H14.6895L10.7198 13.7197C10.6481 13.7889 10.591 13.8717 10.5517 13.9632C10.5124 14.0547 10.4917 14.1531 10.4908 14.2527C10.4899 14.3523 10.5089 14.451 10.5466 14.5432C10.5843 14.6354 10.64 14.7191 10.7105 14.7895C10.7809 14.86 10.8646 14.9157 10.9568 14.9534C11.049 14.9911 11.1477 15.0101 11.2473 15.0092C11.3469 15.0083 11.4453 14.9876 11.5368 14.9483C11.6283 14.909 11.7111 14.8519 11.7803 14.7803L17.0303 9.53025C17.1709 9.3896 17.2498 9.19887 17.2498 9C17.2498 8.80113 17.1709 8.6104 17.0303 8.46975Z"
            fill="#008000"
            fill-opacity="0.6"
          />
        </svg>
      </div>
    </div>
  );
};

export default PkgRoundSliderComponent;
