import React, { useEffect } from 'react';
import './galleryRoundComponent.scss';

const GalleryRoundComponent = () => {

  useEffect(() => {
    const text = document.querySelector('.text');
    text.innerHTML = text.innerText
      .split('')
      .map((char, i) => `<span style="transform:rotate(${i * 10.3}deg)">${char}</span>`)
      .join('');
  }, []);

  return (
    <div className="circle">
      <div className="logog">
        <button>View More Images</button>
      </div>
      <div className="text">
        •GALLERY •GALLERY •GALLERY •GALLERY
      </div>
    </div>
  );
};

export default GalleryRoundComponent;