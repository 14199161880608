import React, { useEffect, useRef } from 'react';

const MapComponent = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapScript = () => {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js? key=AIzaSyB5gZ5e_XhZS42P61I0hWTvLSNZ9znLNg0&callback=initMap&libraries=&v=weekly`;
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener('load', () => {
        if (window.google) {
          const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 22.510416222742442, lng: 88.39932569864659 },
            zoom: 19,
          });

          // Add marker to the map
          const marker = new window.google.maps.Marker({
            position: { lat: 22.510416222742442, lng: 88.39932569864659 },
            map: map,
            title: 'My Marker',
          });
        }
      });
    };

    if (!window.google) {
      loadGoogleMapScript();
    } else {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 40.712776, lng: -74.005974 },
        zoom: 8,
      });

      // Add marker to the map
      const marker = new window.google.maps.Marker({
        position: { lat: 40.712776, lng: -74.005974 },
        map: map,
        title: 'New York',
      });
    }
  }, []);

  return (
    <div>
      <div ref={mapRef} style={{ minHeight: '400px', width: '100%' }}></div>
    </div>
  );
};

export default MapComponent;
