import React from "react";
import "./progressItemComponent.scss";

const ProgressItemComponent = () => {
  const path = window.location.pathname;
  return (
    <div className="flex items-center space-x-2 breadcrumb-container">
     <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6225 6.08932L13.6216 6.08836L7.91069 0.377699C7.66725 0.134121 7.34361 0 6.99938 0C6.65512 0 6.33148 0.134039 6.08796 0.37759L0.380031 6.08535C0.378117 6.08727 0.376203 6.08929 0.374289 6.09123C-0.125609 6.59397 -0.124734 7.40969 0.37675 7.91118C0.605836 8.1404 0.908449 8.27315 1.23198 8.28704C1.24511 8.28833 1.25837 8.28896 1.27171 8.28896H1.49932V12.4917C1.49932 13.3234 2.17597 14 3.00782 14H5.24208C5.46854 14 5.65224 13.8164 5.65224 13.5898V10.2949C5.65224 9.91542 5.96095 9.60673 6.34045 9.60673H7.65828C8.03781 9.60673 8.34647 9.91545 8.34647 10.2949V13.5898C8.34647 13.8164 8.53008 14 8.75663 14H10.9909C11.8228 14 12.4994 13.3234 12.4994 12.4917V8.28898H12.7105C13.0546 8.28898 13.3783 8.15495 13.6219 7.91142C14.1239 7.40909 14.1241 6.59198 13.6225 6.08932Z" fill="#7B7B7B"/>
</svg>


      <svg
        className="breadcrumb-separator"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <path d="M9 5l7 7-7 7" />
      </svg>
      <span className={(!path.includes('inner') && !path.includes("Inner"))?"breadcrumb-highlight": "breadcrumb-text"}>{path.includes('package')?'Package': 'Blogs'}</span>
      {path.includes("inner") || path.includes("Inner") ? (
        <span>
          <svg
            className="breadcrumb-separator"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
          >
            <path d="M9 5l7 7-7 7" />
          </svg>
          <span className="breadcrumb-highlight">Inner</span>
        </span>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default ProgressItemComponent;
