import React, { useEffect, useState } from "react";

import axios from "axios";
import "./doDontComponent.scss";

const DontComponent = () => {


    const [dontsList, setDontsList] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            "https://new.ecstaticexplorers.com/api/dont"
          );
          setDontsList(res.data.data);
        } catch (err) {
          throw err;
        } finally {
        }
      };
      fetchData();
    }, []);




  return (
    <div className="card-box">
      <div className="header">
        <span className="header-icon">
        <svg width="40" height="40" viewBox="0 0 41 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.6783 25.56L2.24452 25.56C1.65685 25.5585 1.09375 25.324 0.67878 24.9079C0.263804 24.4918 0.0308415 23.9281 0.0310042 23.3404L0.0310024 2.43363C0.0308395 1.84596 0.263802 1.28223 0.678777 0.866108C1.09375 0.449993 1.65685 0.215488 2.24452 0.214039L8.6783 0.214038C9.26658 0.215324 9.83039 0.449584 10.2464 0.865562C10.6623 1.28154 10.8966 1.84536 10.8979 2.43363L10.8979 23.3404C10.8966 23.9287 10.6624 24.4925 10.2464 24.9085C9.8304 25.3244 9.26658 25.5587 8.6783 25.56ZM40.0141 18.2809C40.014 19.2842 39.6159 20.2464 38.9074 20.9566C38.847 21.0221 38.782 21.0831 38.7128 21.139C39.4975 21.4716 40.1433 22.065 40.5408 22.8189C40.9384 23.5727 41.0634 24.4408 40.8946 25.2762C40.7258 26.1116 40.2736 26.8631 39.6145 27.4035C38.9554 27.9438 38.1299 28.24 37.2776 28.2417L24.0817 28.2417C24.1547 28.3694 24.2215 28.485 24.2824 28.6005C24.6621 29.2135 24.9644 29.8713 25.1824 30.5586C25.4733 31.5115 25.6508 32.4953 25.7114 33.4897C25.7956 34.9264 25.5915 36.3655 25.1111 37.7221C24.6307 39.0787 23.8837 40.3255 22.9141 41.389C22.5488 41.804 22.1038 42.1411 21.6054 42.3803C21.1071 42.6196 20.5657 42.7561 20.0134 42.7816C19.2591 42.8106 18.5217 42.5535 17.9488 42.0618C17.376 41.5702 17.01 40.8803 16.9243 40.1303C16.9192 40.1022 16.9172 40.0736 16.9182 40.0451L16.9182 34.7181L13.3911 25.7789C13.1023 25.0519 12.6677 24.3917 12.1141 23.839L12.1141 3.23633C12.9847 2.99656 13.8705 2.81575 14.7655 2.69512C15.0981 2.65064 15.4333 2.62829 15.7688 2.62823L33.6533 2.62822C34.5372 2.62938 35.385 2.97898 36.0128 3.6012C36.3217 3.91022 36.5667 4.27715 36.7337 4.68098C36.9006 5.08481 36.9863 5.5176 36.9857 5.95458C36.9879 6.76104 36.6935 7.54015 36.1587 8.14377C36.8631 8.29184 37.5113 8.63587 38.0288 9.13624C38.5462 9.63662 38.9117 10.273 39.0833 10.972C39.2549 11.671 39.2255 12.4043 38.9986 13.0874C38.7717 13.7705 38.3564 14.3756 37.8006 14.833C38.4606 15.1359 39.0199 15.6217 39.4123 16.2328C39.8046 16.8439 40.0134 17.5547 40.0141 18.2809Z" fill="white"/>
</svg>


        </span>
        <h2>Don'ts</h2>
      </div>
      <ul className="list">
        {dontsList.map((item, index) => (
          <li key={index} className="list-item">
          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0C4.48578 0 0 4.48578 0 10C0 15.5142 4.48578 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48578 15.5142 0 10 0Z" fill="#F44336"/>
<path d="M13.6825 12.5041C14.0082 12.8301 14.0082 13.3567 13.6825 13.6826C13.6051 13.7601 13.5133 13.8215 13.4122 13.8634C13.311 13.9053 13.2026 13.9268 13.0932 13.9267C12.8799 13.9267 12.6666 13.8451 12.504 13.6826L9.99991 11.1783L7.49581 13.6826C7.41852 13.7601 7.32668 13.8216 7.22557 13.8635C7.12447 13.9054 7.01608 13.9269 6.90663 13.9268C6.79717 13.9269 6.68877 13.9054 6.58765 13.8635C6.48653 13.8216 6.39468 13.7601 6.31737 13.6826C5.99159 13.3567 5.99159 12.8301 6.31737 12.5041L8.82163 10L6.31733 7.49593C5.99155 7.17 5.99155 6.64339 6.31733 6.3175C6.64327 5.99171 7.16987 5.99171 7.49581 6.3175L9.99991 8.82175L12.504 6.31746C12.83 5.99167 13.3566 5.99167 13.6825 6.31746C14.0082 6.64339 14.0082 7.17 13.6825 7.49593L11.1782 10L13.6825 12.5041Z" fill="#FAFAFA"/>
</svg>

            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DontComponent;
