import { useState } from "react";
import "./reachUsComponent.scss";
import axios from "axios";

const ReachUsComponent = () => {
  const [name, setName] = useState("");
  const [phone, setphone] = useState("");

  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");

  const handleSubmitForm = async () => {
    try {
      const reqBody = {
        name: name,
        phone: phone,
        email: email,
        message: message,
      };
      const postContact = await axios.post(
        "https://new.ecstaticexplorers.com/api/contact-us",
        reqBody
      );
      if (postContact) {
        alert(`Thanks ${name} for show your interest with us.`);
      }
    } catch (err) {
      throw err;
    } finally {
    }
  };

  return (
    <>
      <div className="contact-form">
        <h2>Reach Us Anytime</h2>

        <label htmlFor="name">Name*</label>
          <br />
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            id="name"
            name="name"
            placeholder="Enter your name"
            required
          />
          <br />


        <div className="hero-contact">
          <div>
            {" "}
            <label htmlFor="phone">Phone*</label>
            <br />
            <input
              type="text"
              id="phone"
              onChange={(e) => setphone(e.target.value)}
              name="phone"
              placeholder="Enter phone no."
              required
            />
          </div>

          <div>
          <label htmlFor="email">Email*</label>
        <br />
        <input
          type="email"
          id="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email id"
          required
        />
          </div>
        </div>

       
        <br />

        <label htmlFor="message">Write Your Message*</label>
        <br />
        <textarea
          id="message"
          name="message"
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write message"
          rows="4"
          required
        ></textarea>
        <br />

       <div className="sb-btn">
       <button type="submit" onClick={handleSubmitForm}>
          Submit Now
        </button>
       </div>
      </div>
    </>
  );
};

export default ReachUsComponent;
