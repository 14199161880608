import React, { useEffect, useState } from "react";
import "./pkgFilterSearchComponent.scss";
import axios from "axios";

const citiesData = [
  { name: "Mumbai", count: 39 },
  { name: "Jaisalmer", count: 24 },
  { name: "Manali", count: 55 },
  { name: "Udaipur", count: 30 },
  { name: "Delhi", count: 24 },
  { name: "Jaipur", count: 20 },
  { name: "Goa", count: 15 },
  { name: "Shimla", count: 12 },
  { name: "Chandigarh", count: 18 },
  { name: "Agra", count: 16 },
];

const PkgFilterSearchComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAllCategory, setShowAllCategory] = useState(false);
  const [categoryisOpen, setCategoryIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [checkedCities, setCheckedCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cities = await axios.get(
          "https://new.ecstaticexplorers.com/api/cities"
        );
        setCities(cities.data.data);
        const categoryPackage = await axios.get(
          "https://new.ecstaticexplorers.com/api/categories"
        );
        setCategory(categoryPackage.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };

    fetchData();
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const togglePackageDropdown = () => setCategoryIsOpen(!categoryisOpen);
  const handleSearch = (e) => setSearchTerm(e.target.value);

  const handleCheckboxChange = (cityName) => {
    // setCheckedCities((prevCheckedCities) =>
    //   prevCheckedCities.includes(cityName)
    //     ? prevCheckedCities.filter((name) => name !== cityName)
    //     : [...prevCheckedCities, cityName]
    // );
    setCheckedCities((prevCheckedCities) =>
      prevCheckedCities.includes(cityName)
        ? prevCheckedCities.filter((name) => name !== cityName)
        : [...prevCheckedCities, cityName]
    );
    const newArr = [];
    if (newArr.includes(cityName)) {
    } else {
      newArr.push(cityName);
    }
    // setCheckedCities(newArr)
    props.handleClick(checkedCities);
    console.log("Cityname:", cityName);
    console.log("Checked:", newArr);
  };

  const filteredCities = citiesData.filter((city) =>
    city.name.toLowerCase().includes(searchTerm.toLowerCase())
  ); 
   const filteredCategories = citiesData.filter((city) =>
    city.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedCities = showAll ? cities : cities.slice(0, 6);
  const displayedCategory = showAllCategory ? category : category.slice(0, 6);

  return (
    <div className="cities-filter">
      <>
        <div className="filter-heading package-c-head" onClick={togglePackageDropdown}>
          <h2>Package Category</h2>
          <span className="d-arrow">
            {categoryisOpen ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.50003 10.3125C7.43847 10.3126 7.3775 10.3005 7.32062 10.2769C7.26375 10.2534 7.21209 10.2188 7.16862 10.1752L2.48112 5.48769C2.29796 5.30452 2.29796 5.00792 2.48112 4.82487C2.66429 4.64183 2.96089 4.64171 3.14394 4.82487L7.50003 9.18097L11.8561 4.82487C12.0393 4.64171 12.3359 4.64171 12.5189 4.82487C12.702 5.00804 12.7021 5.30464 12.5189 5.48769L7.83144 10.1752C7.78796 10.2188 7.73631 10.2534 7.67943 10.2769C7.62256 10.3005 7.56159 10.3126 7.50003 10.3125Z"
                  fill="#63AB45"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.50003 4.6875C7.56159 4.6874 7.62256 4.69948 7.67943 4.72301C7.73631 4.74654 7.78796 4.78117 7.83144 4.82475L12.5189 9.51225C12.7021 9.69542 12.7021 9.99202 12.5189 10.1751C12.3358 10.3583 12.0392 10.3583 11.8561 10.1751L7.50003 5.81903L3.14394 10.1751C2.96089 10.3583 2.66429 10.3583 2.48112 10.1751C2.29796 9.99202 2.29796 9.69542 2.48112 9.51225L7.16862 4.82475C7.21209 4.78117 7.26375 4.74654 7.32062 4.72301C7.3775 4.69948 7.43847 4.6874 7.50003 4.6875Z"
                  fill="#63AB45"
                />
              </svg>
            )}
          </span>
        </div>

        {categoryisOpen && (
          <div className="filter-dropdown">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-box"
            />

            <div className="cities-list">
              {displayedCategory.map((city, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    checked={checkedCities.includes(city.id)}
                    onChange={() => handleCheckboxChange(city.id)}
                  />
                  <span className="amount">
                    <span>{city.name}</span>
                    <span className="count">{city.count}</span>
                  </span>
                </label>
              ))}

              {filteredCategories.length > 6 && (
                <div className="show-more" onClick={() => setShowAllCategory(!showAllCategory)}>
                  {showAllCategory ? "Show Less" : "Show More"}
                </div>
              )}
            </div>
          </div>
        )}

        {/* *****************************************
      
       */}

        <div className="filter-heading" onClick={toggleDropdown}>
          <h2>Cities</h2>
          <span className="d-arrow">
            {isOpen ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.50003 10.3125C7.43847 10.3126 7.3775 10.3005 7.32062 10.2769C7.26375 10.2534 7.21209 10.2188 7.16862 10.1752L2.48112 5.48769C2.29796 5.30452 2.29796 5.00792 2.48112 4.82487C2.66429 4.64183 2.96089 4.64171 3.14394 4.82487L7.50003 9.18097L11.8561 4.82487C12.0393 4.64171 12.3359 4.64171 12.5189 4.82487C12.702 5.00804 12.7021 5.30464 12.5189 5.48769L7.83144 10.1752C7.78796 10.2188 7.73631 10.2534 7.67943 10.2769C7.62256 10.3005 7.56159 10.3126 7.50003 10.3125Z"
                  fill="#63AB45"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.50003 4.6875C7.56159 4.6874 7.62256 4.69948 7.67943 4.72301C7.73631 4.74654 7.78796 4.78117 7.83144 4.82475L12.5189 9.51225C12.7021 9.69542 12.7021 9.99202 12.5189 10.1751C12.3358 10.3583 12.0392 10.3583 11.8561 10.1751L7.50003 5.81903L3.14394 10.1751C2.96089 10.3583 2.66429 10.3583 2.48112 10.1751C2.29796 9.99202 2.29796 9.69542 2.48112 9.51225L7.16862 4.82475C7.21209 4.78117 7.26375 4.74654 7.32062 4.72301C7.3775 4.69948 7.43847 4.6874 7.50003 4.6875Z"
                  fill="#63AB45"
                />
              </svg>
            )}
          </span>
        </div>

        {isOpen && (
          <div className="filter-dropdown">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-box"
            />

            <div className="cities-list">
              {displayedCities.map((city, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    checked={checkedCities.includes(city.id)}
                    onChange={() => handleCheckboxChange(city.id)}
                  />
                  <span className="amount">
                    <span>{city.name}</span>
                    <span className="count">{city.count}</span>
                  </span>
                </label>
              ))}

              {filteredCities.length > 6 && (
                <div className="show-more" onClick={() => setShowAll(!showAll)}>
                  {showAll ? "Show Less" : "Show More"}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default PkgFilterSearchComponent;
