import React, { useEffect, useState } from "react";
import "./exploreByStatesComponent.scss";
import CardComponent from "../CardComponent/CardComponent";
import { Link } from "react-router-dom";
import axios from "axios";

const ExploreByStatesComponent = () => {
  const [states, setStates] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://new.ecstaticexplorers.com/api/get-explore-by-states"
        );
        setStates(res.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <div className="explore-cont">
      <h1>Explore By States</h1>

      <div className="explore-by-states">
        <CardComponent
          allStates={states}
          isDescNeeded={true}
          isExploreUnexpected={false}
          parentCont='package'
          isDataFrmApi={true}
          isMostPopularPakage={false}
        />
      </div>
    </div>
  );
};

export default ExploreByStatesComponent;
