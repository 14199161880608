import React, { useEffect } from "react";
import "./cardComponent.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
// import CustomLeftArrow from "../Arrow/CustomLeftArrow";
// import CustomRightArrow from "../Arrow/CustomRightArrow";

let responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 8 },
  desktop: { breakpoint: { max: 3000, min: 1454 }, items: 7 },
  tablet: { breakpoint: { max: 1454, min: 1314 }, items: 7 },
  tablet2: { breakpoint: { max: 1314, min: 1127 }, items: 7 },
  tablet3: { breakpoint: { max: 1127, min: 464 }, items: 4 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
};

const CardComponent = (props) => {
  useEffect(() => {
    props.isRelatedPkg?
    responsive =  {
      superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
      desktop: { breakpoint: { max: 3000, min: 1454 }, items: 3 },
      tablet: { breakpoint: { max: 1454, min: 1314 }, items: 2 },
      tablet2: { breakpoint: { max: 1314, min: 1127 }, items: 2 },
      tablet3: { breakpoint: { max: 1127, min: 464 }, items: 2 },
      mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
    }:
    responsive.tablet2 = { breakpoint: { max: 1314, min: 1127 }, items: 7}
  }, []);

  return (
    <div className="card-container">
      <Carousel
        swipeable={true}
        autoPlay={true}
        infinite={true}
        arrows={true}
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // customLeftArrow={<CustomLeftArrow />}
        // customRightArrow={<CustomRightArrow />}
      >
        {props.allStates.map((item, index) => (
          <Link
            style={{ textDecoration: "none" }}
            to={props.parentCont == "package" ? "/package" : ""}
          >
            <div
              className="img-parent"
              key={index}
              // style={{ top: props.isMostPopularPakage ? '0' : ""}}
              style={{
                height: props.isExploreUnexpected ? "0" : "",
                marginTop: !props.isMostPopularPakage ? "" : "",
              }}
            >
              {!props.isExploreUnexpected ? (
                <div className="crd-txt">
                  <div
                    className="text-cont"
                    style={{
                      top: props.isMostPopularPakage
                        ? "9.9rem"
                        : props.isExploreUnexpected
                        ? "0rem"
                        : "8.3rem",
                        
                    }}
                  >
                    <h3 id="card-name">{item.name}</h3>
                    {props.isDescNeeded ? (
                      <p id="card-desc">
                        Starting at {item.starting_price} Per Person
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="crd-txt" style={{ top: "17.6rem" }}>
                  <div className="text-explore-cont">
                    <h3 style={{ textDecoration: "none", color: props.isRelatedPkg? '#C7FF6C':'' }}>{item.name}</h3>
                    {props.isDescNeeded ? <p>{item.desc}</p> : ""}
                  </div>
                </div>
              )}

              <div className="card-img" style={{width:props.isRelatedPkg? '9rem':''}}>
                <img src={item.file_path} alt={item.name} />
              </div>
            </div>
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default CardComponent;
