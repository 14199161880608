import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./exploreHolidaysComponent.scss";
import rajasthanImg from "../../assets/img/card-rajasthan-img.jpg";
import goaImg from "../../assets/img/card-goa-img.jpg";
import northEasImg from "../../assets/img/card-northeast-img.jpg";
import keralaImg from "../../assets/img/card-kerala-img.jpg";
import southIndiaImg from "../../assets/img/card-southindia-img.jpg";
import SingaporeImg from "../../assets/img/card-singapore-img.jpg";
import KashmirImg from "../../assets/img/card-kasmir-img.jpg";
import thailandImg from "../../assets/img/card-singapore-img.jpg";
import AndamanImg from "../../assets/img/card-andaman-img.jpg";
import sikkimImg from "../../assets/img/card-sikkim-img.jpg";
import { Link } from "react-router-dom";
import axios from "axios";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1424 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1424, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const ExploreHolidaysComponent = () => {
  const [themes, setThemes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const themeData = await axios.get("https://new.ecstaticexplorers.com/api/themes");
        setThemes(themeData.data.data);
      } catch (err) {
        throw err;
      } finally {
      }
    };

    fetchData();
  }, []);
  const holidayList = [
    {
      id: 1,
      cardImg: southIndiaImg,
      title: "Luxe Holidays",
      featuredButtons: ["Beach", "Cultural", "Island", "Hill"],
    },
    {
      id: 2,
      cardImg: northEasImg,
      title: " NEXT-GEN ADVENTURES",
      featuredButtons: ["Adventure Awaits", "Party Paths", "Beach Vibes"],
    },
    {
      id: 3,
      cardImg: keralaImg,
      title: "HONEYMOON",
      featuredButtons: ["Beaches", "Hill Vacays", "Adventure", "City Escapes"],
    },
    {
      id: 1,
      cardImg: goaImg,
      title: "WILD E ",
      featuredButtons: ["Ranthambhon", "Bandhavga", "Andaman", "Japan"],
    },
    {
      id: 2,
      cardImg: rajasthanImg,
      title: " NEXT-GEN ADVENTURES",
      featuredButtons: ["Adventure Awaits", "Party Paths", "Beach Vibes"],
    },
    {
      id: 2,
      cardImg: thailandImg,
      title: " NEXT-GEN ADVENTURES",
      featuredButtons: ["Adventure Awaits", "Party Paths", "Beach Vibes"],
    },
    {
      id: 1,
      cardImg: KashmirImg,
      title: "Luxe Holidays",
      featuredButtons: ["Beach", "Cultural", "Island", "Hill"],
    },
  ];
  return (
    <div className="holiday-container">
      {/* <div className="filer-dark">
        <div className="dark-box"></div>
      </div> */}
      <div className="text-section">
        <p>EXPLORE</p>
        <h1>HOLIDAYS</h1>
        <h3>BY THEME</h3>
        <p className="holiday-info">Pick From Our Specially Curated Packages</p>
      </div>
      <div className="card-section">
        <Carousel
          swipeable={true}
          autoPlay={true}
          infinite={true}
          arrows={true}
          responsive={responsive}
        >
          {holidayList.map((item, index) => {
            return (
              <Link to="/package" style={{ textDecoration: "none" }}>
                <div className="card" key={item.id}>
                  <div className="img-parent">
                    <img src={item.cardImg} alt="" />
                  </div>
                  <div className="card-data">
                    <p>{item.title}</p>
                    <div className="card-btn-parent">
                      {" "}
                      {item.featuredButtons.map((obj) => {
                        return (
                          <div>
                            <button className="card-btn">{obj}</button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="action">
                      {" "}
                      <button>View More</button>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default ExploreHolidaysComponent;
